import React, { Component } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import VideoProvider from './internal/video-provider';

import playIcon from '../images/icons/video-play-button.svg';

export default class SingleVideo extends Component {

  constructor (props) {
    super(props);
    this.state = {
      showOverlayImage: !!this.getOverlayImageSrc(props),
      shouldPlay: false
    };
  }

  playVideo = () => this.setState({
    showOverlayImage: false,
    shouldPlay: true
  });

  getOverlayImageSrc = (props = this.props) => props.relationships.field_image
    && props.relationships.field_image.localFile
    && props.relationships.field_image.localFile.childImageSharp.fluid.src;

  getOverlayTabIndex = () => this.state.showOverlayImage ? 0 : -1;
  
  getVideoTabIndex = () => !this.state.showOverlayImage ? 0 : -1;

  renderOverlayImage = overlayImageSrc => {
    const classes = classNames({
      'overlay-container': true,
      'is-hidden': !this.state.showOverlayImage
    });

    return (
      <div className={classes}>
        <img
          className="overlay-image"
          src={overlayImageSrc}
          alt="Video overlay"          
          tabIndex={this.getOverlayTabIndex()}
          onKeyDown={event => event.key === 'Enter' && this.playVideo()}
          onClick={this.playVideo} />
        <img className="play-icon" src={playIcon} alt="Play video" />
      </div>
    );
  };

  render = () => {
    const overlayImageSrc = this.getOverlayImageSrc();

    return (
      <section
        className="video-component video-component--single">
        {this.props.field_video_url &&
          <VideoProvider
            videoUrl={this.props.field_video_url}
            shouldPlay={this.state.shouldPlay}
            hasOverlay={this.state.showOverlayImage}
            tabIndex={this.getVideoTabIndex()} /> }
        {!!overlayImageSrc && this.renderOverlayImage(overlayImageSrc)}
      </section>
    );
  };
}

export const VideoQuery = graphql`
fragment ParagraphsVideo on paragraph__video {

    field_video_url
    
    relationships {
      field_image {
        localFile {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  
}
`;
import React, { Component } from 'react';
import { graphql } from 'gatsby';

export default class MediaWithCaption extends Component {

    renderImage = (image, index) => {
        if (image && image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.fluid) {
            return <img src={image.localFile.childImageSharp.fluid.src} key={index} className="figure-img img-fluid" alt={this.props.field_images[index]?.alt} />
        } else if (image && image.localFile && image.localFile.publicURL) {
            return <img src={image.localFile.publicURL} key={index} className="figure-img img-fluid" alt={this.props.field_images[index]?.alt} />
        }
        return null;
    };

    render = () => (
        <section className="media-with-caption">
            <div>
                <figure className="figure">
                    {this.props.relationships.field_images.map((image, index) => this.renderImage(image, index))}
                    {(this.props.field_title) ? <figcaption className="figure-caption">{this.props.field_title}</figcaption> : null}
                </figure>
            </div>
        </section>
    );
}

export const MediaWithCaptionQuery = graphql`
fragment ParagraphsMediaWithCaption on paragraph__media_with_caption {

    field_title
    field_images {
        alt
    }
    relationships {
      field_images {
        localFile {
            publicURL
            childImageSharp {
              fluid {
                src
              }
            }
        }
      }
    }
  
}
`;
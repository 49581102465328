const bootstrapBreakpoints = {
    huge: 1200,
    large: 992,
    medium: 768,
    small: 576
};
const bootstrapContainerMaxWidth = {
    huge: 1250,
    large: 960,
    medium: 620,
    small: 540
};

const setSidebarLeftMargin = sidebar => {
    if (sidebar) {
        if (window.innerWidth >= 1200 && window.innerWidth < 1280){
            sidebar.style.marginLeft = (window.innerWidth - 1160) / 2 + 'px';
        } else if (window.innerWidth >= bootstrapBreakpoints.huge && window.innerWidth - bootstrapContainerMaxWidth.huge > 0) {
            sidebar.style.marginLeft = (window.innerWidth - bootstrapContainerMaxWidth.huge) / 2 + 'px';
        } else if (window.innerWidth >= bootstrapBreakpoints.large && window.innerWidth < bootstrapBreakpoints.huge) {
            sidebar.style.marginLeft = (window.innerWidth - bootstrapContainerMaxWidth.large) / 2 + 'px';
        } else if (window.innerWidth >= bootstrapBreakpoints.medium && window.innerWidth < bootstrapBreakpoints.large) {
            sidebar.style.marginLeft = (window.innerWidth - bootstrapContainerMaxWidth.medium) / 2 + 'px';
        } else if (window.innerWidth >= bootstrapBreakpoints.small && window.innerWidth < bootstrapBreakpoints.medium) {
            sidebar.style.marginLeft = (window.innerWidth - bootstrapContainerMaxWidth.small) / 2 + 'px';
        } else {
            sidebar.style.marginLeft = '0';
        }
    }
}

export default setSidebarLeftMargin;
import React, { Component } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import Slider from 'react-slick';

import VideoProvider from './internal/video-provider';
import Links from '../utils/links';
import removeSlickButtonsFromTabOrder from '../utils/tab-order';
import { deepCloneObject } from '../utils/deep-clone';

import playIcon from '../images/icons/video-play-button.svg';

export default class VideoAndText extends Component {

  state = {
    showOverlayImage: {},
    shouldStop: {},
    shouldPlay: {},
    itemInViewIndex: 0
  };

  componentDidMount = () => {
    const newState = deepCloneObject(this.state);
    this.props.relationships.field_items.forEach((item, index) => {
      newState.showOverlayImage[index] = !!this.getOverlayImageSrc(item);
      newState.shouldStop[index] = false;
    });
    this.setState(newState);
    removeSlickButtonsFromTabOrder();
  };

  playVideo = index => {
    const newState = deepCloneObject(this.state);
    newState.showOverlayImage[index] = false;
    newState.shouldPlay[index] = true;
    this.setState(newState);
  }

  renderOverlayImage = (overlayImageSrc, key) => {
    const classes = classNames({
      'overlay-container': true,
      'is-hidden': !this.state.showOverlayImage[key]
    });

    return (
      <div className={classes}>
        <img
          className="overlay-image"
          src={overlayImageSrc}
          alt="Video overlay"
          tabIndex={this.getOverlayTabIndex(key)}
          onKeyDown={event => event.key === 'Enter' && this.playVideo(key)}
          onClick={() => this.playVideo(key)} />
        <img className="play-icon" alt="Play icon" src={playIcon} />
      </div>
    );
  };

  getOverlayImageSrc = item => item.relationships.field_image
    && item.relationships.field_image.localFile
    && item.relationships.field_image.localFile.publicURL;

  renderVideoElement = (item, key) => {
    const overlayImageSrc = this.getOverlayImageSrc(item);

    return (
      <div className="inner-content video-component" key={key}>
        {item.field_video_url &&
          <VideoProvider
            videoUrl={item.field_video_url}
            shouldPlay={this.state.shouldPlay[key]}
            shouldStop={this.state.shouldStop[key]}
            hasOverlay={this.state.showOverlayImage[key]}
            tabIndex={this.getVideoTabIndex(key)} /> }
        {!!overlayImageSrc && this.renderOverlayImage(overlayImageSrc, key)}
      </div>
    );
  }

  getVideoTabIndex = key => this.state.itemInViewIndex === key && !this.state.showOverlayImage[key] ? 0 : -1;

  getOverlayTabIndex = key => this.state.itemInViewIndex === key && this.state.showOverlayImage[key] ? 0 : -1;

  renderLink = () => this.props.links.length !== 0
    ? Links.renderLink(this.props.links[0], 'btn btn-lg btn-blue')
    : null;

  setContent = () => (this.props.field_text && this.props.field_text.processed) || '';

  render = () => {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        { breakpoint: 768, settings: { slidesToShow: 1 } },
        { breakpoint: 1024, settings: { slidesToShow: 1 } }
      ],
      beforeChange: (previousItemInView, itemInViewIndex) => {
        const newState = deepCloneObject(this.state);
        for (const key in newState.showOverlayImage) {
          newState.shouldStop[key] = true;
        }
        this.setState(Object.assign(newState, { itemInViewIndex }));
      }
    };

    let carouselClass = 'carousel-block';
    if(!this.props.field_gray_background) {
      carouselClass+=' white'
    }

    return (
      <section className={carouselClass}>
        <div className="container">
          <div>
            <div className="content">
              <div className="left-content">
                <Slider {...settings}>
                  {this.props.relationships.field_items.map(this.renderVideoElement)}
                </Slider>
              </div>
              <div className={`right-content ${this.props.relationships.field_items.length > 1 ? 'has-slick-dots' : ''}`}>
                <div className="content text-left">
                  <h2>{this.props.field_title}</h2>
                  <div dangerouslySetInnerHTML={{ __html: this.setContent() }}>
                  </div>
                  {this.renderLink()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  };
}

export const VideoAndTextQuery = graphql`
fragment ParagraphsVideoAndText on paragraph__video_and_text {
  field_title
  field_gray_background
  field_text {
    processed
  }
  links {
    alias
    title
    fieldname
  }
  relationships {
    field_items {
      field_video_url
      relationships {
        field_image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
}
`;
import React, {Component} from 'react';
import { graphql, Link } from 'gatsby';

/**
 *
 */
export default class TwoCardsGradientBorder extends Component {

	/**
	 * Component constructor
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.data = props;
	}

	renderLink(link) {
        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(link.alias);
        if (internal) {
            return (
                <Link to={link.alias} className="btn btn-dark btn-sm">
                    {link.title}
                </Link>
            );
        }

        return (
            <a target="_blank" className="btn btn-dark btn-sm" rel="noopener noreferrer" href={link.alias}>
                {link.title}
            </a>
        );
    }

	renderLinkTitle(link, title) {
        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(link.alias);
        if (internal) {
            return (
                <Link to={link.alias}>
                    <h4>{title}</h4>
                </Link>
            );
        }

        return (
            <a target="_blank" rel="noopener noreferrer" href={link.alias}>
                <h4>{title}</h4>
            </a>
        );
    }

	setContent (step) {
		if (step && step.field_text && step.field_text.processed) {
			return step.field_text.processed;
		} else {
			return '';
		}
	}



	renderStep(step, counter) {
		
		const lastCard = counter === this.data.relationships.field_two_cards.length;

		return (
			<div className="col-lg-6" key={counter}>
				<div className={"col" + ((lastCard) ? ' blue-gradient' : '')}>
					<div className="inner">
						{(step.relationships.field_image && step.relationships.field_image.localFile && step.relationships.field_image.localFile.publicURL) ? (<img height='80' src={step.relationships.field_image.localFile.publicURL} alt="icon" />) : ''}
	                    {step.links.length > 0 ? (<>{this.renderLinkTitle(step.links[0], step.field_title)}</>) : <h4>{step.field_title}</h4>}
	                    
	    				<div dangerouslySetInnerHTML={{__html: this.setContent(step)}}></div>

						{step.links.length > 0 && (<>{this.renderLink(step.links[0])}</>)}

    				</div>
				</div>
			</div>
		)
	}

	/**
	 * Component renderer
	 * @returns {*}
	 */
	render() {
	    return(
	    	<section className="two-blocks">
		        <div className="container">
		    		<div className="row row-eq-height">
		    			{ this.data.relationships.field_two_cards.map((component,idx) => this.renderStep(component, idx+1)) }
		        	</div>
		        </div>
	        </section>
	    )
	}
};

export const TwoCardsGradientBorderQuery = graphql`
fragment ParagraphsTwoCardsGradientBorder on paragraph__two_cards_gradient_border {
  
  relationships {
	field_two_cards {
	  field_title
	  field_text {
		processed
	  }

	  links {
		alias
		title
	  }
	  
	  relationships {
		field_image {
		  localFile {
			publicURL
		  }
		}
	  }
	}
  }
  
}
`;

import React, {Component} from 'react';
import {graphql} from 'gatsby';
import ComponentsManager from "../../utils/components-manager";
import SidebarMenu from "../../layout/sidebar-menu"


/**
 * This components renders sidebar layout
 */
export default class SidebarLayout extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;
    }


    /**
     * Render menu
     * @returns {*}
     */
    render() {
        let classes = "sidebar-steps"
        if (!this.data.field_show_gradient) {
            classes += " white-gradient"
        } else { 
            if (this.data.field_gradient_color === 'Teal') {
                classes += " teal"
            } else if (this.data.field_gradient_color === 'Navy') {
                classes += " navy"
            } else {
                classes += " blue"
            }
        }
        
        return (

            <section className="sidebar-layout container">
                <div className="row">
                    <div className="col-lg-4 col-side">
                        <div className={classes}>
                            <div className="row">
                                <div className="col-12 col-sm-12 sidebar-menu">
                                    <SidebarMenu />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 inner-page mt-0 sidebar-content">
                        <ComponentsManager components={this.data.relationships.field_items}/>
                    </div>
                </div>
            </section>
        )
    }
};


// ...ParagraphsPlainAreaText
// ...ParagraphsMediaWithCaption
// ...ParagraphsVideo
// ...ParagraphsQuoteStatement
//
// ...ParagraphsAccordion
// ...ParagraphsResources

export const SidebarLayoutQuery = graphql`
fragment ParagraphsSidebarLayout on paragraph__sidebar_layout {
    field_gradient_color
    field_show_gradient
    relationships {
    field_items {
      __typename
      
      ...ParagraphsPeople
      
      ...ParagraphsPlainAreaText
      ...ParagraphsSingleCardGradientBorder
      ...ParagraphsQuoteStatement
      ...ParagraphsBlueBackgroundText
      ...ParagraphComponentIntro
      ...ParagraphHowItWorks
      ...ParagraphsMediaWithCaption
      ...ParagraphNewsletterSignup
      ...ParagraphsPurpleCards
      ...ParagraphsResources
      ...ParagraphsSingleCardGradientBorder
      ...ParagraphTextCtaImage
      ...ParagraphsTwoCardsGradientBorder
      ...ParagraphsVideoAndText
      ...ParagraphsSingleCardGradientBorderB
      ...ParagraphsBluePhotoBanner
    }
  }
}
`;
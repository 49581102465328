import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Wistia extends Component {

  static propTypes = {
    videoId: PropTypes.string.isRequired,
    shouldPlay: PropTypes.bool,
    shouldStop: PropTypes.bool,
    hasOverlay: PropTypes.bool,
    tabIndex: PropTypes.number
  };

  constructor() {
    super(); {
      this.state = {
        video: null
      };
    }
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      window._wq = window._wq || [];
      window._wq.push({
        id: this.props.videoId,
        onReady: video => this.setState({ video })
      });

      if (!window.document.getElementById(`wistia-medias-${this.props.videoId}`)) {
        this.loadWistiaMedias();
      }

      if (!window.document.getElementById('wistia-assets')) {
        this.loadWistiaAssets();
      }
    }
  };

  UNSAFE_componentWillUpdate = props => {
    if (this.state.video && props.shouldPlay) {
      this.state.video.play();
    }
    if (this.state.video && props.shouldStop) {
      this.state.video.pause();
    }
  };

  loadWistiaMedias = () => {
    const wistiaMedias = document.createElement('script');
    wistiaMedias.id = `wistia-medias-${this.props.videoId}`;
    wistiaMedias.src = `//fast.wistia.com/embed/medias/${this.props.videoId}.jsonp`;
    wistiaMedias.async = true;
    window.document.body.append(wistiaMedias);
  };

  loadWistiaAssets = () => {
    const wistiaAssets = document.createElement('script');
    wistiaAssets.id = 'wistia-assets';
    wistiaAssets.src = '//fast.wistia.com/assets/external/E-v1.js';
    wistiaAssets.async = true;
    window.document.body.append(wistiaAssets);
  };

  render() {
    const classes = classNames({
      wistia_embed: true,
      [`wistia_async_${this.props.videoId}`]: true,
      'is-hidden': this.props.hasOverlay
    });

    return (
      <div
        id={`wistia-${this.props.videoId}-1`}
        className={classes}
        tabIndex={this.props.tabIndex}>&nbsp;</div>
    );
  }
}

export default Wistia;
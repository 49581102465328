import React from "react";
import {Link} from "gatsby";

export default class Links {



    /**
     * @description Render single menu item
     * @param link
     * @param classes
     * @returns {*}
     */
    static renderLink(link, classes) {
        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(link.alias);
        if (internal) {
            return (
                <Link className={classes} to={link.alias}>{link.title}</Link>
            );
        }

        return (
            <a className={classes} target="_blank" rel="noopener noreferrer" href={link.alias}>{link.title}</a>
        );
    }


    /**
     * @description Render single menu item
     * @param link
     * @param classes
     * @param key
     * @returns {*}
     */
    static renderLinkKey(link, classes, key) {
        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(link.alias);
        if (internal) {
            return (
                <Link key={key} className={classes} to={link.alias}>{link.title}</Link>
            );
        }

        return (
            <a key={key} className={classes} target="_blank" rel="noopener noreferrer" href={link.alias}>{link.title}</a>
        );
    }

    static renderLinkKeySelf(link, classes, key) {
        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(link.alias);
        if (internal) {
            return (
                <Link key={key} className={classes} to={link.alias}>{link.title}</Link>
            );
        }

        return (
            <a key={key} className={classes} target="_self" rel="noopener noreferrer" href={link.alias}>{link.title}</a>
        );
    }


    /**
     * Appends schema to url if doesn't exist
     * @param url
     * @returns {*}
     */
    static fixSalesforceLink(url)
    {
        let httpString = 'http://',
            httpsString = 'https://'
        ;

        if (url.substr(0, httpString.length) !== httpString && url.substr(0, httpsString.length) !== httpsString)
            url = httpString + url;

        return url;
    }

    /**
     * Removes schema if added to link and prepares link to be shown as text
     * @param url
     * @returns {*}
     */
    static fixSalesforceLinkText(url)
    {
        return url.replace(/(^\w+:|^)\/\//, '');
    }


    static wrapURLs(text, new_window)
    {
        var url_pattern = /(?![^<]*>|[^<>]*<\/)((http:|https:)\/\/[a-zA-Z0-9&#=.\/\-?_]+)/ig;
        var target = (new_window === true || new_window == null) ? '_blank' : '';

        return text.replace(url_pattern, function (url) {
            var protocol_pattern = /^(?:(?:https?|ftp):\/\/)/i;
            var href = protocol_pattern.test(url) ? url : 'http://' + url;
            return '<a href="' + href + '" target="' + target + '">' + url + '</a>';
        });
    }


};
import React, { Component } from 'react';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import schoolFallbackImage from '../../images/school-fallback-image.svg';

export default class AssociatedColleges extends Component {
    renderSchool (school, index) {
        return (
            <div key={index} className="associated-colleges-list-item">
                <div className="image-container">
                    <Link to={school.alias}>
                        <img src={school.image || schoolFallbackImage} alt={school.title} />
                    </Link>
                </div>
                <div className="school-info-container">
                    <Link to={school.alias}>{school.title}</Link>
                    <span>{school.location}</span>
                </div>
            </div>
        );
    }

    render () {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            useCss: false,
            slidesToShow: 1, 
            slidesToScroll: 1,
            variableWidth: false,
            swipeToSlide: true
        };

        return (
            <section className="associated-colleges container">
                <h3>Affiliated colleges</h3>
                <div className="associated-colleges-list">
                    {
                        this.props.schools
                            .slice(0, 4)
                            .map((school, index) => this.renderSchool(school, index))
                    }
                </div>
                <div className="associated-colleges-list associated-colleges-list--with-slider">
                    <Slider {...settings}>
                        {
                            this.props.schools
                                .slice(0, 4)
                                .map((school, index) => this.renderSchool(school, index))
                        }
                    </Slider>
                </div>
            </section>
        );
    }
}
/**
 * Remove objects from array with same given property
 * @param originalArray
 * @param objectKey
 * @returns {Array}
 */
const removeObjectDuplicates = (originalArray, objectKey) =>
    originalArray.filter((obj, pos, array) =>
        array.map(mapObj => mapObj[objectKey]).indexOf(obj[objectKey]) === pos);

export default removeObjectDuplicates;
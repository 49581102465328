import React, { Component } from 'react';
import { graphql, Link, navigate } from 'gatsby';

import Checkbox from './internal/checkbox';
import generateFilters from './explore/generate-filters';

import iconTelescope from '../images/icons/icon-telescope.svg';
import iconArrow from '../images/icons/black-arrow.svg';

/**
 * This components renders explore colleges
 * @see C3
 */
export default class ExploreColleges extends Component {

	state = {
		searchQuery: '',
		checkedItems: new Map()
	}
	
	handleChange = event => {
	    const item = event.target.name;
		const isChecked = event.target.checked;
	    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
	}

	updateSearchQuery = event => this.setState({ searchQuery: event.target.value })
	
	onSearch = event => {
		event.preventDefault();

		const checkedFilters = Array.from(this.state.checkedItems).filter(el => el[1]).map(el => ({ id: el[0] }));
		const filters = generateFilters(checkedFilters);
		const params = new URLSearchParams();
		params.set('filter', btoa(JSON.stringify(filters)));
		params.set('q', btoa(this.state.searchQuery));
		navigate(`/explore/?${params.toString()}`);
	}

	renderCheckboxes = checkboxes => checkboxes.map((item, index) => (
		<div key={index} className={this.state.checkedItems.get(item.id) ? 'chiller_cb active' : 'chiller_cb'}>
			<Checkbox id={item.id} name={item.id} checked={this.state.checkedItems.get(item.id)} onChange={this.handleChange} />
			<label htmlFor={item.id}>{item.name}</label>
			<span></span>
		</div>
	))

	render = () => (
		<section className="container search-component">
			<div className="inner">
				<div className="row">
					<div className="col-sm-12">
						<h2 dangerouslySetInnerHTML={{__html: this.props.field_ec_title}}></h2>
					</div>
				</div>
				<div className="row">
					<form role="search" onSubmit={this.onSearch}>
						<div className="form-group">
						<img className="d-none d-lg-block d-xl-block balloon-icon" src={iconTelescope} alt="telescope icon" />
						<input
								value={this.state.searchQuery}
								onChange={this.updateSearchQuery}
								type="text"
								className="form-control"
								aria-label="search colleges"
								placeholder={this.props.field_ec_search_text} />
						</div>
						<div className="form-group">
						<div className="main-label">
							<label className="text-black">{ this.props.field_ec_tags_title }</label>
						</div>
						<>
							{ this.renderCheckboxes(JSON.parse(this.props.field_ec_filter_tags)) }
						</>
						</div>
						<button type="submit" className="btn btn-lg btn-dark">Start</button>							
						<Link to="/explore/" className="link-arrow text-black">
							Or view all colleges <img src={iconArrow} alt="icon arrow" />
						</Link>
					</form>
				</div>
			</div>
		</section>
	)
};

export const ExploreCollegesQuery = graphql`
fragment ParagraphsExploreColleges on paragraph__explore_colleges {
  
  field_ec_title
  field_ec_search_text
  field_ec_next_button_title
  field_ec_tags_title
  field_ec_view_all_title
  field_ec_tags_title
  field_ec_filter_tags
  
}`;
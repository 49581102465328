export const insertIntoArray = (array, value) => {

    return array.reduce((result, element, index, array) => {

        result.push(element);

        // insert an element after every 3rd item
        if (((index + 1) % 3 === 0) && (index < array.length - 1)) {
            result.push(value.shift());
        }

        return result;
    }, []);
};
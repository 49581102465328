import React, {Component} from 'react';
import {graphql, Link} from 'gatsby';


/**
 *
 */
export default class SingleCardGradientBorder extends Component {


	/**
	 * Component constructor
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.data = props;
	}


	button(key, link)
	{
		const classes = (key === this.data.links.length-1) ? 'btn btn-lg btn-dark' : 'btn btn-lg btn-dark btn-first';

		// Is link external or internal? If it starts with slash ( / ), its internal
		const internal = /^\/(?!\/)/.test(link.alias);

		if (internal) {
			return (
				<Link className={classes} key={key} to={link.alias}>{link.title}</Link>
			);
		}

		return (
			<a href={link.alias} className={classes} key={key} target="_blank" rel="noopener noreferrer">{link.title}</a>
		)
	}



	/**
	 * Component renderer
	 * @returns {*}
	 */
	render() {

		return (
			<section className="one-block">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-inner col-lg-12 col-xl-8">
							<div className="inner">
								{
									(this.data.relationships.field_image && this.data.relationships.field_image.localFile) ?
										(<img src={this.data.relationships.field_image.localFile.publicURL} alt="icon" />) :
										null
								}

								<h2 dangerouslySetInnerHTML={{__html: this.data.field_title}}></h2>
								<div dangerouslySetInnerHTML={{__html: this.data.field_text.processed}}></div>
								{this.data.links.map((link, i) => this.button(i, link))}
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}

};

export const SingleCardGradientBorderQuery = graphql`
fragment ParagraphsSingleCardGradientBorder on paragraph__single_card_gradient_border {
  field_title
  field_text {
	processed
  }
  
  links {
    alias
    title
    fieldname
  }
  
  relationships {
	field_image {
	  localFile {
          publicURL
	  }
	}
  }
}
`;



import React, { Component } from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';

import iconTelescope from '../../images/icons/icon-telescope.svg';
import searchIcon from '../../images/icons/explore-search.png';
import iconSearchResultSchool from '../../images/icons/icon-search-results-school.svg';
import exploreFilters from './explore-filters';

/**
 * Header component with search functionality + autocomplete
 */
class ExploreHeader extends Component {

    state = {
        debounceThreshold: 500,
        searchQuery: '',
        forceClose: false,
        displayResults: false,
        lastChangeTimestamp: null,
        matches: []
    }

    componentDidMount = () => {
        if (typeof window !== 'undefined') {
            this.setQueryString();
            this.attachListeners();
        }
    }

    componentWillUnmount = () => {
        window.document.body.removeEventListener('click', this.handleClick);
        window.document.body.removeEventListener('focus', this.handleFocus, true);
    }

    setQueryString = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const searchQueryParam = searchParams.get('q');
        if (searchQueryParam) {
            this.setState({ searchQuery: atob(searchQueryParam) });
        }
    }

    handleClick = event => {
        if (!event.target.closest('.explore-search-input-wrapper') && !event.target.closest('.search-button-container')) {
            this.setState({ displayResults: false });
        }
    }

    handleFocus = event => {
        if (!event.target.closest('.explore-search-input-wrapper')) {
            this.hideSearchResults();
        }
    }

    attachListeners = () => {
        window.document.body.addEventListener('click', this.handleClick);
        window.document.body.addEventListener('focus', this.handleFocus, true);
    }

    searchSchools = () => {
        this.hideSearchResults();
        this.setState({ forceClose: true }, () => setTimeout(() => this.setState({ forceClose: false }), this.state.debounceThreshold));
        this.setPageResults();
        this.updateURL();
    }

    setPageResults = () => {
        const params = new URLSearchParams(window.location.search);
        const filterParams = params.get('filter');
        const filters = filterParams ? JSON.parse(atob(filterParams)) : exploreFilters;
        this.props.setPageResults(filters, this.state.searchQuery);
    }

    updateURL = () => {
        const params = new URLSearchParams(window.location.search);
        params.set('q', btoa(this.state.searchQuery));
        const pathname = window.location.pathname.endsWith('/') ? window.location.pathname : `${window.location.pathname}/`;
        const url = `${pathname}?${params.toString()}`;
        window.history.replaceState({url}, '', url);
    }

    renderTopResults = () => this.state.matches.slice(0, 5).map((school, index) => (
        <li className="search-result-item" key={index}>
            <Link to={school.getUri()}>
                <img src={iconSearchResultSchool} alt="Search"/>
                <span>{school.getName()}</span>
            </Link>
        </li>
    ))

    renderSearchAllResultsButton = () => (
        <li className="overflow-result-item">
            <button onClick={() => this.searchSchools()}>
                Show all results for "{this.state.searchQuery}"
            </button>
        </li>
    )

    renderSearchResults = () => {
        return (
            <ul className="search-result-container">
                { this.renderTopResults() }
                { this.state.matches.length > 5 && this.renderSearchAllResultsButton() }
            </ul>
        );
    }

    onSubmit = event => {
        event.preventDefault();
        this.searchSchools();
    }

    hasPassedThreshold = () => (new Date().getTime() - this.state.lastChangeTimestamp) > this.state.debounceThreshold;

    hideSearchResults = () => this.setState({ displayResults: false });

    updateSearchResults = () => {
        const matches = this.props.findMatches(this.state.searchQuery);
        this.setState({ matches, displayResults: !!matches.length });
    }

    handleDebounce = () => {
        setTimeout(() => {
            if (this.state.searchQuery && this.hasPassedThreshold()) {
                this.state.forceClose ? this.setState({ forceClose: false }) : this.updateSearchResults();
            }
        }, this.state.debounceThreshold);
    }

    handleChange = event => this.setState({
        searchQuery: event.target.value,
        lastChangeTimestamp: new Date().getTime(),
        displayResults: event.target.value && this.state.displayResults
    }, this.handleDebounce)

    handleKeyUp = event => {
        if (event.key === 'Escape') {
            this.hideSearchResults();
        }
    }

    render = () => {
        return (
            <div className="explore-header">
                <div className="container">
                    <img src={iconTelescope} alt="icon telescope"/>
                    <div className="row">
                        <div className="col">
                            <h1>Explore colleges</h1>
                        </div>
                        <form className="col explore-search-container" onSubmit={this.onSubmit}>
                            <div
                                className={`explore-search-input-wrapper ${this.state.displayResults ? 'has-results' : ''}`}>
                                <img src={searchIcon} alt="Magnifying glass."/>
                                <input
                                    autoFocus={true}
                                    value={this.state.searchQuery}
                                    onChange={this.handleChange}
                                    onKeyUp={this.handleKeyUp}
                                    aria-label="search colleges"
                                    placeholder="Search Common App colleges by name"/>
                                {this.state.displayResults && this.renderSearchResults()}
                            </div>
                            <div className="search-button-container">
                                <button type="submit">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

ExploreHeader.propTypes = {
    findMatches: PropTypes.func
};

export default ExploreHeader;

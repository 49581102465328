import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class YouTube extends Component {

  static propTypes = {
    videoId: PropTypes.string.isRequired,
    shouldPlay: PropTypes.bool,
    shouldStop: PropTypes.bool,
    hasOverlay: PropTypes.bool,
    tabIndex: PropTypes.number
  };

  iframe = React.createRef();

  UNSAFE_componentWillUpdate = props => {
    if (props.shouldStop) {
      this.iframe.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }
  }

  render = () => (
    <div className={classNames({ 'is-hidden': this.props.hasOverlay })}>
      <iframe
        className="youtube"
        src={`https://www.youtube.com/embed/${this.props.videoId}?autoplay=${+!!this.props.shouldPlay}&rel=0&modestbranding=1&enablejsapi=1`}
        ref={this.iframe}
        tabIndex={this.props.tabIndex}
        title="video"
        aria-label="YouTube video"
        type="text/html"
        allowFullScreen
        frameBorder="0" />
    </div>
  );
}

export default YouTube;
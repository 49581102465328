import React, {Component} from 'react';
import iconArrow from '../images/icons/black-arrow.svg';
import {graphql, Link} from "gatsby";

/**
 *
 */
export default class PurpleCards extends Component {


    /**
     * Component constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;
    }

    renderLink(link) {
      // Is link external or internal? If it starts with slash ( / ), its internal
      const internal = /^\/(?!\/)/.test(link.alias);
      if (internal) {
          return (
              <Link className="text-black" to={link.alias}>{link.title} <img src={iconArrow} alt="icon arrow" /></Link>
          );
      }

      return (
          <a className="text-black" target="_blank" rel="noopener noreferrer" href={link.alias}>{link.title} <img src={iconArrow} alt="icon arrow" /></a>
      );
  }

    /**
     * Renders a single card
     *
     * @TODO based on the number of cards we should add appropriate class, col-sm-4 might not always work, not sure
     */
    renderCard(key, card)
    {

        const icon = (card.relationships.field_image && card.relationships.field_image.localFile) ? card.relationships.field_image.localFile.publicURL : null;
        const title = card.field_title;
        const text = card.field_text.processed;
        const colorCard = this.data.field_color;
        const link = (card.links.length > 0) ? card.links[0] : null;

        return (
            <div className="col-md-4" key={key}>
              <div className="col-inner">
                  { icon ? (

                      <div className="img-holder">
                          <img height='80' src={icon} alt={card.field_image.alt}/>
                      </div>
                  ) : null}
                <h5 className={colorCard}>{title}</h5>
                <div dangerouslySetInnerHTML={{__html: text}}>
                </div>
                {(link) ? this.renderLink(link) : null}
              </div>
            </div>
        )
    }


    /**
     * Component renderer
     * @returns {*}
     */
    render() {

        let classes = "purple-cards";

        return (
            <section className={classes}>
                <div className="container">
                  <div className="wrapper">
                    <div className="row">
                        {this.data.relationships.field_cards.map((card, key) => this.renderCard(key, card))}
                    </div>
                  </div>
                </div>
            </section>
        )
    }

};


export const ThreeCardsBlueGradientQuery = graphql`
fragment ParagraphsPurpleCards on paragraph__purple_cards {
  field_color
  relationships {
    field_cards {
    
      field_title
      field_text {
        processed
      }
      links {
        alias
        title
        fieldname
      }
      field_image {
        alt
      }
      relationships {
        field_image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
}
`;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import ComponentsManager from '../utils/components-manager';
import * as Scroll from 'react-scroll';
import Slider from "react-slick";
import iconTrademark from "../images/CAReady_Logo_RGB.png";

/**
 * Render application steps
 */
class ApplicationSteps extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props) {
        super(props);

        this.data = props;
        this.state = {
            top: 0,
            activeToggle: typeof window !== 'undefined' ? this.findActiveToggleIndex() : 0
        };


    }

    findActiveToggleIndex () {
        const activeToggleIndex = this.props.links.findIndex(link => {
            const href = window.location.href.endsWith('/') ?
                            window.location.href.substring(0, window.location.href.length - 1) :
                            window.location.href;
            return href.endsWith(link.alias);
        });
        return activeToggleIndex === -1 ? 0 : activeToggleIndex;
    }

    componentDidMount () {
        document.body.addEventListener('keydown', this.handleKeydown);
        document.body.addEventListener('mousedown', this.handleMouseDown);
    }
    
    componentWillUnmount = () => {
        document.body.removeEventListener('keydown', this.handleKeydown);
        document.body.removeEventListener('mousedown', this.handleMouseDown);
    }

    handleKeydown = event => {
        if (event.code === 'Tab') {
            document.body.classList.remove('is-not-tab');
        }
    }

    handleMouseDown = () => {
        document.body.classList.add('is-not-tab');
    }

    /**
     *
     * @param step
     * @param counter
     * @returns {*}
     */
    renderStepHeading(step, counter) {
        return (
            <div>
                <div id={this.getIdFromText(step.field_title)}></div>
                {this.data.field_disable_counter ? null : (
                    <span className="num-of-step">{counter}.</span>
                )}
                {step.field_title ? <h4>{step.field_title}</h4> : null}

            </div>
        );
    }

    /**
     *
     * @param name
     * @returns {string}
     */
    getIdFromText (name) {
        return name.toLowerCase().trim().split(' ').join('-');
    }

    /**
     *
     * @param step
     * @param counter
     * @returns {*}
     */
    renderStep(step, counter) {

        if(this.data.field_disable_sidebar)
        {
            return this.renderStepNoSidebar(step, counter)
        }

        // remove margin if navigation is disabled -> ie component is used as a part of a page, not page wrapper
        const rightSideClasses = (this.data.field_disable_navigation) ? 'col-lg-8 content' : 'col-xl-7 col-lg-8 content';

        let trademarkImage = false;

        // if field_color_scheme is set to "blue", add appropriate gradient stylings
        let leftSideClasses = 'sidebar-steps';
        if(this.data.field_color_scheme === 'blue') {
            leftSideClasses += ' blue';
        }
        //@TODO Cyan if for application steps on "Your path to college"
        if(this.data.field_color_scheme === 'cyan') {
            leftSideClasses += ' blue';
        }

  
        let descClass = 'step-description';
        if(this.data.field_disable_counter){
          descClass += ' only';
        }

        let divClasses = "row";
        if(counter === 1)
        {
            divClasses += " first-content";

            if (this.data.field_show_trademark_ca_logo) {
                trademarkImage = true;
            }
        }

        return (
            <div className={divClasses} key={counter*24}>
                <div className="col-lg-4 col-side" aria-label="Col">
                    <div className={leftSideClasses}>
                        <div className="row">
                            <div className="col-num col-12 col-sm-12" aria-label="Content" tabIndex="0">
                                {this.renderStepHeading(step, counter)}
                                {(step.field_text) ? <div className={descClass} dangerouslySetInnerHTML={{ __html: step.field_text.processed }} /> : null}
                            </div>
                        </div>
                        {trademarkImage ? this.renderTrademarkIconHtml() : null}
                    </div>
                </div>
                <div className={rightSideClasses}>
                    <ComponentsManager node={step} components={step.relationships.field_step_content} />
                </div>
            </div>
        )

    }

    renderTrademarkIconHtml() {
        return (
            <div className="row">
                <div className="col-num col-12 col-sm-12" aria-label="Content" tabIndex="0">
                    <img className='ca-trademark' src={iconTrademark} />
                </div>
            </div>
        )
    }

    /**
     *
     * @param step
     * @param counter
     * @returns {*}
     */
    renderStepNoSidebar(step, counter)
    {

        return (

            <div className="row school-section" key={counter*24} id={this.getIdFromText(step.field_title)}>
                <div className="col-lg-12 content no-sidebar-step">
                    <ComponentsManager node={step} components={step.relationships.field_step_content} />
                </div>
            </div>
        )
    }

    /**
     * Render single menu item
     * @returns {*}
     * @param href
     * @param text
     * @param key
     */
    renderLink(href, text, key) {
        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(href)
        if (internal) {
            return (
                <Link key={key} to={href}>{text}</Link>
            )
        }

        return (
            <a key={key} target="_blank" rel="noopener noreferrer" href={href}>{text}</a>
        )
    }

    /**
     * Renders toggle buttons if they're present
     *
     * @returns {*}
     */
    renderToggles() {
        const toggleLinks = this.data.links;

        return (
            <div className="application-steps-toggles">
                <div className="toggles-container">
                    {toggleLinks.map((link, key) => this.renderToggle(link, key))}
                </div>
            </div>
        )
    }

    renderToggle (link, key) {
        return (
            <div key={key} className="language-item">
                <Link
                    key={key}
                    className={`toggle-pill ${this.isActiveToggle(key) ? 'toggle-pill--active' : ''}`}
                    to={link.alias}>
                    {link.title}
                </Link>
            </div>
        );
    }

    isActiveToggle (key) {
        return this.state.activeToggle === key;
    }

    /**
     *
     */
    renderNavigation() {

        let numOfSlides = this.data.relationships.field_steps.length;
        const renderToggles = this.data.links.length > 0;
        let settings = {};
      

        if(numOfSlides > 4 && !renderToggles) {
          settings = { 
            dots: false, 
            infinite: false, 
            speed: 800, 
            arrows: true, 
            slidesToShow: 4, 
            slidesToScroll: 2, 
            variableWidth: true,
            initialSlide: 0,
            responsive: [ 
              { breakpoint: 500, settings: { slidesToShow: 1, slidesToScroll: 1} }, 
              { breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1} }, 
              { breakpoint: 991, settings: { slidesToShow: 1, slidesToScroll: 1 } },
              { breakpoint: 1200, settings: { slidesToShow: 1, slidesToScroll: 1 } },
              { breakpoint: 5000, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } }
            ]
          };
        } else if (!renderToggles){
          settings = { 
            dots: false, 
            infinite: false, 
            speed: 800, 
            arrows: false, 
            slidesToShow: 1, 
            slidesToScroll: 1, 
            variableWidth: true,
            initialSlide: 0,
            responsive: [ 
              { breakpoint: 500, settings: { arrows: true, slidesToShow: 1, slidesToScroll: 1} },
              { breakpoint: 991, settings: { arrows: true, slidesToShow: 1, slidesToScroll: 1} },
              { breakpoint: 1200, settings: { slidesToShow: 1, slidesToScroll: 1 } },
              { breakpoint: 5000, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } }
            ]
          };
        } 
        else {
          settings = { 
            dots: false, 
            infinite: false, 
            speed: 800, 
            arrows: false, 
            slidesToShow: 1,
            slidesToScroll: 1, 
            variableWidth: true,
            initialSlide: 0,
            responsive: [ 
              { breakpoint: 500, settings: { arrows: true, slidesToShow: 1, slidesToScroll: 1} }, 
              { breakpoint: 767, settings: { arrows: true, slidesToShow: 1, slidesToScroll: 1} }, 
              { breakpoint: 2000, settings: { arrows: true, slidesToShow: 2, slidesToScroll: 2 } },
              { breakpoint: 5000, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } }
            ]
          };
        }

        
        if(!this.data.field_slim_steps_navigation){
          return (
            <div>
              <div className="subnav">
                  <Slider {...settings}>
                    {this.data.relationships.field_steps.map((item, key) => (
                        <div className={`navigation-item ${this.props.field_disable_counter ? 'navigation-item--no-counter' : ''}`} key={key}>

                            <Scroll.Link to={this.getIdFromText(`${item.field_title}`)}
                                         smooth={true}
                                         offset={-100}
                                         duration={500}>
                                {this.props.field_disable_counter ? item.field_title : `${++key}. ${item.field_title}`}
                            </Scroll.Link>

                        </div>
                    ))}
                  </Slider>
              </div>
            </div>
          );
        } else {
          return (

            <div className="subnav">
                  <Slider {...settings}>
                    {this.data.relationships.field_steps.map((item, key) => (
                        <div className={`navigation-item ${this.props.field_disable_counter ? 'navigation-item--no-counter' : ''}`} key={key}>

                            <Scroll.Link to={this.getIdFromText(`${item.field_title}`)}
                                         smooth={true}
                                         offset={-100}
                                         duration={500}>
                                {this.props.field_disable_counter ? item.field_title : `${++key}. ${item.field_title}`}
                            </Scroll.Link>

                        </div>
                    ))}
                  </Slider>
              </div>
            );
        }

        
    }

    /**
     * Renders steps navigation and toggle buttons
     * @returns {*}
     */
    renderHeading() {
        if (this.data.field_disable_navigation)
            return null;

        const renderToggles = this.data.links.length > 0;

        // If with toggles, render toggles
        if (renderToggles) {
            return (
                <div className="row heading">
                    <div className="col-md-12 col-lg-4 text-center order-sm-2 order-lg-1">
                        {this.renderToggles()}
                    </div>
                    <div className="col-md-12 col-lg-8 text-center order-sm-1 order-lg-2">
                        {this.renderNavigation()}
                    </div>
                </div>
            )
        }

        // If no toggles, don't render toggles
        return (
            <div className="row heading nav-only-container">
                <div className="col-md-12 text-center nav-only">
                    {this.renderNavigation()}
                </div>
            </div>
        )
    }


    /**
     * Render menu
     * @returns {*}
     */
    render() {
        let sectionClasses = "application-steps";
        if(this.data.field_slim_steps_navigation)
        {
            sectionClasses += " slim-steps-navigation"
        }

        return (
            <section className={sectionClasses}>
              <div className="container">
                {this.renderHeading()}

                {(this.data.field_title) ? (
                    <div className="row">
                        <div className="col-md-12 text-center tagline">
                            {this.data.field_title}
                        </div>
                    </div>
                ) : null}

                {this.data.relationships.field_steps.map((step, idx) => this.renderStep(step, idx + 1))}
              </div>
            </section>
        )
    }
};

export const ApplicationStepsQuery = graphql`
fragment ParagraphsApplicationSteps on paragraph__application_steps {
  field_title
  field_disable_counter
  field_show_trademark_ca_logo
  field_disable_navigation
  field_disable_sidebar
  field_color_scheme
  field_slim_steps_navigation
  
  links {
    alias
    title
    fieldname
  }
  
  relationships {
    field_steps {
      field_title
      field_text {
        processed
      }
      
      relationships {
        field_step_content {
          __typename
          ...ParagraphsPlainAreaText
          ...ParagraphsTipBox        
          ...ParagraphsTermBox
          ...ParagraphsAccordion
          ...ParagraphsResources
          ...ParagraphsVideo
          ...ParagraphsDownloadableContent
          ...ParagraphPathToCollegeCard
        }
      }
      
      
    }
  }
}
`;

ApplicationSteps.propTypes = {
    links: PropTypes.array,
    relationships: PropTypes.object,
    field_disable_navigation: PropTypes.bool,
    field_disable_counter: PropTypes.bool,
    field_show_trademark_ca_logo: PropTypes.bool,
    field_title: PropTypes.string
};

export default ApplicationSteps;
import React, {Component} from 'react';
import {graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Links from '../utils/links';

import HeroPlaceholderImage from '../images/hero_placeholder.png';


/**
 * This components renders hero
 */
export default class Hero extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
          mobileView: false
        };
        
    }

    componentDidMount () {
        this.getViewportWidth();
        window.addEventListener('resize', this.getViewportWidth);
    }
    

    componentWillUnmount () {
        window.removeEventListener('resize', this.getViewportWidth);
    }

    getViewportWidth = () => {
      const innerWidth = Math.min(window.innerWidth, window.screen.width);
      const mobileView = innerWidth < 992;
      this.setState({ mobileView });
    };

    isSchoolHero () {
        return this.props.field_hero_school ? 'hero-member-profile' : '';
    }

    isDarkModeEnabled () {
        return this.props.field_dark_mode ? 'dark-mode' : '';
    }

    /**
     * Checks if hero is narrow and adds appropriate class
     * @returns {string}
     */
    isNarrowHero() {
        const narrowHero = this.data.field_narrow_hero;
        if (narrowHero === true) {
            return `hero-section text-white hero-shorter ${this.isSchoolHero()} ${this.isDarkModeEnabled()}`;
        } else {
            return `hero-section text-white ${this.isSchoolHero()} ${this.isDarkModeEnabled()}`;
        }
    }

    renderText (){
        const textHero = this.data.field_hero_text;
        if(textHero){
          return (<p>{textHero}</p>);
        } else {
          return '';
        }
    }

    /**
     * Renders button links
     * @returns {*}
     */
    renderLinks() {
        const links = this.data.links;

        if (!links || links.length === 0) {
            return null;
        }

        // Get first link -- hero can't have more then 1


        // Render button of appropriate styling
        const buttonClass = this.data.field_dark_mode ? 'btn-dark' : 'btn-white';

        return links.map((link, key) => this.state.mobileView ?
            Links.renderLinkKeySelf(link, 'btn btn-lg btn-blue', key) :
            Links.renderLinkKeySelf(link, `btn btn-lg ${buttonClass}`, key)
        );
    }

    /**
     * Falls back to default hero image if not set
     * @returns {*}
     */
    resolveHeroImage()
    {
        //console.log("HERO", this.data);
        if(this.data &&
            this.data.relationships &&
            this.data.relationships.field_hero_image &&
            this.data.relationships.field_hero_image.localFile &&
            this.data.relationships.field_hero_image.localFile.childImageSharp )
        { 
            return (
                <GatsbyImage image={this.data.relationships.field_hero_image.localFile.childImageSharp.gatsbyImageData}
                 className="hero-image"
                 alt={this.props.field_hero_image.alt}
                 style={{ position: `absolute` }} />
            )
        }
        if(this.data &&
            this.data.relationships &&
            this.data.relationships.field_hero_image &&
            this.data.relationships.field_hero_image.localFile &&
            this.data.relationships.field_hero_image.localFile.publicURL )
        {
            return (
                <img src={this.data.relationships.field_hero_image.localFile.publicURL} className="hero-image" alt="" style={{ position: `absolute` }} />
            )
        }


        return (

          <img src={HeroPlaceholderImage} className="hero-image" alt="" />

        );
    }


    /**
     * Render menu
     * @returns {*}
     */

    renderTitle () {
       if(this.props.field_is_this_the_lead_hero == null) {
            return <h1>{this.data.field_hero_title}</h1> 
       } else {
            return this.props.field_is_this_the_lead_hero?  <h1>{this.data.field_hero_title}</h1> : <h2>{this.data.field_hero_title}</h2>  
       }
    }

    render() {

        if(this.data.field_image_first_on_mobile){
          return (
            <div className="hero-main-section">
                <section className={this.isNarrowHero()}>
                    {this.resolveHeroImage()}
                    <div className="container d-none d-sm-none d-md-none d-lg-block d-xl-block">
                        <div className="row">
                            <div className="col col-xl-7 col-lg-9 col-sm-12">
                                {this.renderTitle()}
                                {this.renderText()}
                                <div>{this.renderLinks()}</div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bellow-hero d-block d-sm-block d-md-block d-lg-none d-xl-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                {this.renderTitle()}
                                {this.renderText()}
                                <div>{this.renderLinks()}</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>



          );
        } else {
          return (
            <div className="hero-main-section">
                <section className="above-hero d-block d-sm-block d-md-block d-lg-none d-xl-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                {this.renderTitle()}
                                {this.renderText()}
                                <div>{this.renderLinks()}</div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={this.isNarrowHero()}>
                    {this.resolveHeroImage()}
                    <div className="container d-none d-sm-none d-md-none d-lg-block d-xl-block">
                        <div className="row">
                            <div className="col col-xl-7 col-lg-9 col-sm-12">
                                {this.renderTitle()}
                                {this.renderText()}
                                <div>{this.renderLinks()}</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
          );
        }
        


    }
};

export const HeroQuery = graphql`
fragment ParagraphHero on paragraph__hero {
      
  field_hero_title
  field_hero_text
  field_narrow_hero
  field_image_first_on_mobile
  field_dark_mode
  field_is_this_the_lead_hero
  field_hero_image {
    alt
  }
  
  links {
    alias
    title
    fieldname
  }
  
  relationships {
    field_hero_image {
      localFile {
        childImageSharp {
            gatsbyImageData(quality: 100)
        }
      }
    }
  }
}`;
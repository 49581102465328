import React, { Component } from 'react';
import { navigate } from 'gatsby';
import MarkerClusterer from '@google/markerclusterer';
import markerSelected from '../../images/icons/marker-selected.svg';
import markerIcon from '../../images/icons/marker.svg';
import markerClustererSmall from '../../images/icons/map-circle-35-35.svg';
import markerClustererMedium from '../../images/icons/map-circle-50-50.svg';
import markerClustererLarge from '../../images/icons/map-circle-65-65.svg';
import mapStyles from '../internal/map-styles';

class GoogleMap extends Component {
    state = {
        zoom: 4,
        selectedSchool: null,
        results: this.props.results,
        shouldShowInfoWindow: false
    }
    map = React.createRef();

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.renderInitialMap = this.renderInitialMap;
            if (!global.google || !global.google.maps) {
                this.loadScript();
            } else {
                this.renderInitialMap();
            }
        }
    }

    UNSAFE_componentWillReceiveProps (props) {
        // selected school
        if (this.isDifferentSelectedSchool(props.selectedSchool)) {
            const oldSelectedSchool = this.state.selectedSchool;
            this.setState({ shouldShowInfoWindow: true, selectedSchool: props.selectedSchool }, () => {
                window.mapInstance.setZoom(18);
                this.replaceSelectedSchoolMarker(oldSelectedSchool);
                window.mapInstance.panTo({lat: this.props.selectedSchool.getLat(), lng: props.selectedSchool.getLng()});
                this.updateSchoolsInBounds();
            });
        }
        // searching and filtering
        if (this.state.results !== props.results) {
            this.setState({ results: props.results }, () => {
                this.updateMarkers();
                this.zoomAndCenterAtInit();
            });
        }
    }

    componentWillUnmount () {
        window.mapInstance = null;
        window.markers.forEach(marker => marker.setMap(null));
        window.markerClusterer.clearMarkers();
        window.markers = [];
    }

    isFirstSelectedSchool (newSelectedSchool) {
        return newSelectedSchool && this.state.selectedSchool === null;
    }

    isUpdatedSelectedSchool (newSelectedSchool) {
        return this.state.selectedSchool && newSelectedSchool && newSelectedSchool.getName() !== this.state.selectedSchool.getName();
    }

    isDifferentSelectedSchool (newSelectedSchool) {
        return this.isFirstSelectedSchool(newSelectedSchool) || this.isUpdatedSelectedSchool(newSelectedSchool);
    }

    onMarkerClick (school, callback = () => {}) {
        if (school === this.state.selectedSchool) {
            this.setState({ shouldShowInfoWindow: !this.state.shouldShowInfoWindow }, () => callback());
        } else {
            this.setState({ shouldShowInfoWindow: true }, () => {
                this.props.onMarkerClick(school);
                callback();
            });
        }
    }

    replaceSelectedSchoolMarker (oldSelectedSchool) {
        if (oldSelectedSchool !== null) {
            const markerOldSelectedSchool = window.markers.find(marker => marker.position.lat() === oldSelectedSchool.getLat());
            if (markerOldSelectedSchool) {
                markerOldSelectedSchool.setIcon(markerIcon);
                const oldInfoWindow = window.infoWindows.find(infoWindow => infoWindow.marker === markerOldSelectedSchool);
                oldInfoWindow.close();
            }
        }

        const markerNewSelectedSchool = window.markers.find(marker => marker.position.lat() === this.state.selectedSchool.getLat());
        const infoWindow = window.infoWindows.find(infoWindow => infoWindow.marker === markerNewSelectedSchool);
        infoWindow.open(window.mapInstance, markerNewSelectedSchool);
        markerNewSelectedSchool.setIcon(markerSelected);
    }

    updateSchoolsInBounds () {
        if (window.mapInstance) {
            const mapBounds = window.mapInstance.getBounds();
            const schoolsInBounds = this.props.results
                .filter(school => mapBounds.contains({lat: school.getLat(), lng: school.getLng()}));
            this.props.onBoundsChange(schoolsInBounds);
        }
    }

    loadScript () {
        const ref = window.document.getElementsByTagName('script')[0];
        const script = window.document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBcf_YnZaGbwASQ4O3a4FAOVewNyQXfUjA&callback=renderInitialMap`;
        script.async = false;
        ref.parentNode.insertBefore(script, ref);
    }

    updateMarkers () {
        if (window.markers) {
            window.markers.forEach(marker => marker.setMap(null));
            window.markerClusterer.clearMarkers();
        }
        this.renderMarkers();
    }

    generateInfoWindow (school) {
        const infoWindow = document.createElement('div');
        infoWindow.className = 'marker-info-window';

        const imageWrapper = document.createElement('div');
        imageWrapper.className = 'image-wrapper';

        const imageLink = document.createElement('a');
        imageLink.href = school.getUri();
        imageLink.onclick = event => {
            event.preventDefault();
            navigate(school.getUri());
        };
        
        const image = document.createElement('img');
        image.src = school.getGalleryImages()[0];
        image.alt = 'College campus';

        imageLink.appendChild(image);
        imageWrapper.appendChild(imageLink);

        infoWindow.appendChild(imageWrapper);

        const schoolDetails = document.createElement('div');
        schoolDetails.className = 'school-details';

        const nameLink = document.createElement('a');
        nameLink.href = school.getUri();
        nameLink.onclick = event => {
            event.preventDefault();
            navigate(school.getUri());
        };

        nameLink.textContent = school.getName();
        schoolDetails.appendChild(nameLink);

        const cityState = document.createElement('span');
        cityState.textContent = school.getCityState();
        schoolDetails.appendChild(cityState);

        infoWindow.appendChild(schoolDetails);
        return infoWindow;
    }

    renderMarkers () {
        window.infoWindows = [];
        window.markers = this.props.schoolsInBounds.map(school => {
            const options = {
                position: {lat: school.getLat(), lng: school.getLng()},
                map: window.mapInstance,
                icon: {url: school === this.state.selectedSchool ? markerSelected : markerIcon}
            };
            const marker = new window.google.maps.Marker(options);
            const infoWindow = new window.google.maps.InfoWindow({
                content: this.generateInfoWindow(school)
              });
            infoWindow.marker = marker;
            window.infoWindows.push(infoWindow);
            marker.addListener('click', () => {
                this.onMarkerClick(school, () => {
                    if (this.state.shouldShowInfoWindow) {
                        infoWindow.open(window.mapInstance, marker);
                    } else {
                        infoWindow.close();
                    }
                });
            });              
            return marker;
        });
        const options = {
            averageCenter: true,
            styles: [
                {
                    textColor: '#0B6DBD',
                    textSize: '14',
                    url: markerClustererSmall,
                    height: 39,
                    width: 39
                  },
                 {
                    textColor: '#0B6DBD',
                    url: markerClustererMedium,
                    height: 54,
                    width: 54
                  },
                 {
                    textColor: '#0B6DBD',
                    url: markerClustererLarge,
                    height: 69,
                    width: 69
                  }
            ]
        };
        window.markerClusterer = new MarkerClusterer(window.mapInstance, window.markers, options);
    }

    renderInitialMap = () => {
        const mapOptions = {
            zoom: 7,
            // TODO: change these to US center
            center: new window.google.maps.LatLng(-34.397, 150.644),
            styles: mapStyles,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: true,
            fullscreenControl: false,
            clickableIcons: true
        };
        window.mapInstance = new window.google.maps.Map(this.map.current, mapOptions);
        this.limitZoom();
        this.setMapListeners();
        this.renderMarkers();
        this.zoomAndCenterAtInit();
    }

    limitZoom () {
        window.mapInstance.addListener('zoom_changed', () => {
            if (window.mapInstance.getZoom() > 18) {
                window.mapInstance.setZoom(18);
            }
        });
    }

    setMapListeners () {
        window.mapInstance.addListener('dragend', () => this.updateSchoolsInBounds());
        window.mapInstance.addListener('zoom_changed', () => this.updateSchoolsInBounds());
    }

    zoomAndCenterAtInit () {
        if (this.props.allSchools.length === this.props.results.length) {
            this.fitMainlandSchools();
        } else {
            this.fitSchoolsInBounds(this.props.results);
        }
    }
    
    fitMainlandSchools () {
        const mainlandSchools = this.props.results.filter(school => this.isMainlandUS(school));
        this.fitSchoolsInBounds(mainlandSchools);
    }

    fitSchoolsInBounds (schools) {
        const bounds = new window.google.maps.LatLngBounds();
        schools.forEach(school => {
            const lat = school.getLat();
            const lng = school.getLng();
            if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
                bounds.extend(new window.google.maps.LatLng(lat, lng));
            }
        });
        window.mapInstance.fitBounds(bounds, 10);
    }

    isMainlandUS (school) {
        const region = school.getRegion();
        const mainlandRegions = ['New England', 'Mid-Atlantic', 'South', 'Southwest', 'Midwest', 'West'];
        return mainlandRegions.includes(region);
    }

    render() {
        return <div ref={this.map} className="google-maps-wrapper" />;
    }
}

export default GoogleMap;
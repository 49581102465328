import React, {Component} from 'react';
//import { graphql } from 'gatsby';


import iconArrowBlack from '../images/icons/black-arrow.svg';
import iconArrowWhite from '../images/icons/white-arrow.svg';
import {graphql, Link} from "gatsby";

/**
 * This Resources
 */
export default class Resources extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props)
	{
        super(props);
        this.data = props;
    }


    /**
     * @description Render single menu item
     * @param link
     * @returns {*}
     */
    renderLink(link) {
        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(link.alias);
        let textClass = "text-black"
        let iconArrow = iconArrowBlack
        if(this.props.field_background_color === 'Blue') {
            textClass = "text-white"
            iconArrow = iconArrowWhite
        }
        if (internal) {
            return (
                <Link className={textClass} to={link.alias}>{link.title} <img src={iconArrow} alt="icon arrow" /></Link>
            );
        }

        return (
            <a className={textClass} target="_blank" rel="noopener noreferrer" href={link.alias}>{link.title} <img src={iconArrow} alt="icon arrow" /></a>
        );
    }

    renderLinkTitle(link, card) {
        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(link?.alias);
        if (internal) {
            return (
                <Link to={link?.alias}><h4>{card.field_title}</h4></Link>
            );
        }

        return (
            <a target="_blank" rel="noopener noreferrer" href={link?.alias}><h4>{card.field_title}</h4></a>
        );
    }

    /**
     * Renders single resource card
     * @param key
     * @param card
     * @param cardsInChunk
     */
    renderCard(key, card, cardsInChunk)
    {

        let classes = "";
        let innerClass= "inner c18";
        const link = (card.links.length > 0) ? card.links[0] : null;

        if(cardsInChunk === 1)
            classes = "col-12 col-md-12 col";

        if(cardsInChunk > 1)
            classes = "col-12 col-md-6 col";

        if(!card.field_text)
            innerClass = "inner without-text";

        if (this.props.field_background_color === 'Teal') {
            innerClass += ' card-teal'
        } else if (this.props.field_background_color === 'Blue') {
            innerClass += ' card-blue'
        }            

        return (
            <div className={classes} key={key}>
                <div className={innerClass}>
                    {(card.field_title) ? this.renderLinkTitle(link, card) : null}

                    {(card.field_text) ? <div dangerouslySetInnerHTML={{ __html: card.field_text.processed}}></div> : null}

                    {(link) ? this.renderLink(link) : null}
                </div>
            </div>
        )

    }


    /**
     * @TODO move to utils
     * @param array
     * @param size
     * @returns {Array}
     */
     chunk(array, size) {
        const chunked_arr = [];
        for (let i = 0; i < array.length; i++) {
            const last = chunked_arr[chunked_arr.length - 1];
            if (!last || last.length === size) {
                chunked_arr.push([array[i]]);
            } else {
                last.push(array[i]);
            }
        }
        return chunked_arr;
    }


    /**
     * Render Resources
     * @returns {*}
     */
    render()
    {
        const cardRows = this.chunk(this.data.relationships.field_cards, 2);

        return (
            <div className="resources-container">
                { (this.data.field_title) ? <h3>{this.data.field_title}</h3> : null }

                {cardRows.map((row, key) => (
                    <div className="row" key={key}>
                        {row.map((card, key) => this.renderCard(key, card, row.length))}
                    </div>
                ))}

            </div>
        )
    }
};
export const ResourcesQuery = graphql`
fragment ParagraphsResources on paragraph__resources {

  field_title
  field_background_color
  relationships {
    field_cards {
      field_title
      field_text {
        processed
      }
      links {
        alias
        title
        fieldname
      }
    }
  }
  
}
`;
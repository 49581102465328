import React, {Component} from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import { BREADCRUMBS } from '../constants/url';
import iconTriangle from '../images/icons/arrow-main-nav-icon-black.svg';

/**
 * This components renders breadcrumbs
 */
export default class Breadcrumbs extends Component {

    /**
     * Component constuctor
     * @param {Object} props
     */
    constructor(props) {
        super(props);

        this.data = props;
        this.state = {
            pathFragments: [],
            siblingPaths: [],
            areSiblingPathsExpanded: false
        };        
    }

    componentDidMount () {
        const pathFragments = window.location.pathname.replace(/\/$/, "")
                                                      .split('/')
                                                    //   .slice(1)
                                                      .map(fragment => ({ label: fragment, href: fragment }) );
        const lastFragment = pathFragments.pop();
        lastFragment.label = this.props.node.title;
        pathFragments.push(lastFragment);
        this.setState({ pathFragments });
        this.attachBreacrumbsCloseHandler();
    }

    componentWillUnmount = () => window.document.body.removeEventListener('click', this.handleClick)

    attachBreacrumbsCloseHandler = () => {
        if (typeof window !== 'undefined') {
            window.document.body.addEventListener('click', this.handleClick);
        }
    }

    handleClick = event => {
        if (!event.target.closest('.expand-sibling-paths-button') && !event.target.classList.contains('expand-sibling-paths-button')) {
            this.setState({ areSiblingPathsExpanded: false });
        }
    }

    expandPath () {
        !this.state.areSiblingPathsExpanded && !this.state.siblingPaths.length ?
            axios
                .get(window.backendUrl + BREADCRUMBS + '?id=' + this.props.breadcrumbCategory)
                //.then(response => this.setState({ siblingPaths: response.data, areSiblingPathsExpanded: true })) :
                .then(response => this.uniqueItemsForBreadcrumbs(response.data)) :
            this.setState({ areSiblingPathsExpanded: !this.state.areSiblingPathsExpanded });

    }

    uniqueItemsForBreadcrumbs(data) {
        // unset same url in new siblings breadcrumbs
        let notUuniqueItems = [];
        data.forEach((item) =>
            this.state.pathFragments.forEach(function (item2) {
                if(item.title === item2.label) {
                    notUuniqueItems.push(item);
                }
            })
        );

        let uniqueItems = data.filter(function(el) { return el.title !== notUuniqueItems[0].title; });


        this.setState({ siblingPaths: uniqueItems, areSiblingPathsExpanded: true});
    }

    renderSiblingPath (path, key) {
        return (
            <li key={key}>
                <Link to={path.alias.replace("//","/")}>
                    { path.title }
                </Link>
            </li>
        );
    }

    renderSiblingPaths () {
        return (
            <ul className={`sibling-paths ${this.shouldDisplaySiblingPaths() ? 'sibling-paths--is-visible' : 'sibling-paths--is-hidden'}`}>
                { this.state.siblingPaths.map((path, key) => this.renderSiblingPath(path, key)) }
            </ul>
        );
    }

    /**
     * Renders a single breadcrumb list item with an anchor element
     * @param {string} fragment 
     * @returns {Object}
     */
    renderBreadcrumb (fragment, key, isLastFragment) {

        const showExpandIcon = isLastFragment && this.props.breadcrumbCategory;

        let capitalizedText = '';

        if (isLastFragment) {
            const text = fragment.label.split('#')[0];
            capitalizedText = text.charAt(0).toUpperCase() + text.substring(1);
        } else {
            capitalizedText = fragment.label
                .split('#')[0]
                .split('-').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
        }

        const href = this.state.pathFragments
            .map(fragment => (fragment.href === '') ? '/' : fragment.href)
            .reduce((pathSoFar, next) => (pathSoFar.endsWith(fragment.label)) ? pathSoFar : `${pathSoFar}/${next}`);

        if(isLastFragment) {
            return (
                <li className="breadcrumb-item" key={key*478}>

                    {
                        showExpandIcon ?
                        <>
                            <div>
                                <button
                                    className={`expand-sibling-paths-button ${this.state.areSiblingPathsExpanded ? 'expand-sibling-paths-button--active' : ''}`}
                                    onClick={() => this.expandPath(fragment)}>
                                    {capitalizedText}
                                    <img src={iconTriangle} alt="Right arrow." />
                                </button>
                            </div>
                            { this.renderSiblingPaths() }
                        </> : 
                        <div>
                            {capitalizedText}
                        </div>
                    }
                </li>
            );
        } else {
            const ariaLabel = capitalizedText ? {} : { 'aria-label': 'Home page' };
               if(capitalizedText === 'Blog') {
                capitalizedText = 'News and updates'
                }
            return (
                <li className="breadcrumb-item" key={key*478}>
                    <Link to={href.replace("//","/")} {...ariaLabel}>{capitalizedText}</Link>
                    {
                        showExpandIcon &&
                        <>
                            <div>
                                <button
                                    className={`expand-sibling-paths-button ${this.state.areSiblingPathsExpanded ? 'expand-sibling-paths-button--active' : ''}`}
                                    onClick={() => this.expandPath(fragment)}>
                                    <img src={iconTriangle} alt="Right arrow." />
                                </button>
                            </div>
                            { this.renderSiblingPaths() }
                        </>
                    }
                </li>
            );
        }



    }

    shouldDisplaySiblingPaths () {
        return this.state.siblingPaths.length && this.state.areSiblingPathsExpanded;
    }


    /**
     * Renders breadcrumbs
     * @returns {Object}
     */
    render () {
        return (
            <nav aria-label="breadcrumb" className="breadcrumb-container">
                <div className="container">
                    <div className="row">
                        <ol className="breadcrumb">
                            {this.state.pathFragments.map((fragment, key) => this.renderBreadcrumb(fragment, key, key === this.state.pathFragments.length - 1))}
                        </ol>
                    </div>
                </div>
            </nav>
        )
    }
};

import React, {Component} from 'react';
import { graphql } from 'gatsby';
import Links from '../utils/links';


import airBalloonIllustration from '../images/icons/air-balloon-illustration.svg';



/**
 * This components renders hero
 */
export default class HeroGradient extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props)
	  {
        super(props);
        this.data = props;
    }

    /**
     * Renders illustrations
     * @returns {*}
     */
    renderIllustrations()
    {
        return (
            <>
                <img src={this.data.relationships.field_image?.localFile.publicURL ? this.data.relationships.field_image.localFile.publicURL : airBalloonIllustration} className="balloon-icon" alt={this.data.field_image?.alt ? this.data.field_image.alt : "air balloon icon" } />
            </>
        );
    }

    renderButton()
    {
        if(this.data.links.length === 0)
        {
            return null;
        }
        const link = this.data.links[0];

        return (
            Links.renderLink(link, "btn btn-lg btn-white")
        )
    }


    /**
     * Render menu
     * @returns {*}
     */
    render()
    {

        let classes = "hero-illustration hero-gradient";
        if(this.data.field_gradient_color_banner === 'Blue-Sky') {
            classes += " hero-gradient-blue"
        } else if (this.data.field_gradient_color_banner === 'Teal-Green') {
            classes += " hero-gradient-teal"
        } else if(this.data.field_gradient_color_banner === 'Navy-Purple') {
            classes += " hero-gradient-navy"
        }
        if(!this.data.field_hero_text) {
          classes += " hero-with-icons";
        }

        return (
            <section className={classes}>
                <div className="container">
                    <div className="row">
                        <div className="col col-xl-7 offset-xl-2 content-wrapper">
                            {this.data.field_is_this_the_lead_hero? <h1>{this.data.field_hero_title}</h1> : <h2>{this.data.field_hero_title}</h2>}
                            <p>{this.data.field_hero_text}</p>
                            {this.renderButton()}
                        </div>
                    </div>
                    { this.data.field_remove_background_image ? null : this.renderIllustrations() }
                </div>
            </section>
        )
    }
};

export const HeroGradientQuery = graphql`
fragment ParagraphHeroGradient on paragraph__hero_gradient {
  field_hero_title
  field_hero_text
  field_gradient_color_banner
  field_is_this_the_lead_hero
  links {
    alias
    title
  }
  field_remove_background_image
  field_image {
    alt
  }
  relationships {
    field_image {
      localFile {
        publicURL
      }
    }
  }
}`;
import { deepCloneArray } from './../../utils/deep-clone';
import exploreFilters from './explore-filters';

const generateFilters = checkedFilters => {
    const filters = deepCloneArray(exploreFilters);
    checkedFilters.forEach(checkedFilter => filters.forEach(filter => filter.controls.forEach(control => {
        if (control.id === checkedFilter.id) {
            control.isActive = true;
        }
    })));
    return filters;
}

export default generateFilters;
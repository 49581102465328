import React, {Component} from 'react';
import { graphql } from 'gatsby';

/**
 *
 */
export default class ComponentIntro extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;
    }


    /**
     * Render menu
     * @returns {*}
     */
    render()
    {
        return (
            <section className="container text-component">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <h2>{this.data.field_title}</h2>
                        {this.data.field_text && (<div dangerouslySetInnerHTML={{__html: this.data.field_text.processed}} />)}
                    </div>
                </div>
            </section>
        )
    }
};

export const ComponentIntroQuery = graphql`
fragment ParagraphComponentIntro on paragraph__component_intro {
  field_title
  field_text {
    processed
  }
}
`;
import React, {Component} from 'react';
import { graphql } from 'gatsby';
import Links from '../utils/links';

/**
 *
 */
export default class TextCtaImage extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;
    }

    renderButton()
    {

        if(this.data.links.length === 0)
        {
            return null;
        }

        const link = this.data.links[0];

        return Links.renderLink(link, 'btn btn-lg btn-blue');
    }

    renderImage()
    {

        if(this.data && this.data.relationships && this.data.relationships.field_image && this.data.relationships.field_image.localFile){
            return (<img src={this.data.relationships.field_image.localFile.publicURL} alt={this.data.field_image.alt} />);
        }
        return '';
        
    }

    /**
     * Render menu
     * @returns {*}
     */
    render()
    {

        let classes = "mobile-download";
        if(this.data.field_gray_background)
        {
            classes += " mobile-download-graybg";
        }

        return (
            <section className={classes}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 d-block d-lg-none d-xl-none">
                            <div className="content">
                                {this.renderImage()}
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <div className="content text-left">
                                <h2>{this.data.field_title}</h2>
                                <div dangerouslySetInnerHTML={{__html: this.data.field_text.processed}}></div>
                                {this.renderButton()}
                            </div>
                        </div>
                        <div className="col-lg-5 d-none d-lg-block d-xl-block">
                            <div className="content">
                                {this.renderImage()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};

export const TextCtaImageQuery = graphql`
fragment ParagraphTextCtaImage on paragraph__text_cta_image {
  field_title
  field_image {
    alt
  }
  field_gray_background
  field_text {
    processed
  }
  links {
    alias
    title
    fieldname
  }
  relationships {
    field_image {
      localFile {
        publicURL
      }
    }
  }
}
`;
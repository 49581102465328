import React, {Component} from 'react';
import * as PropTypes from "prop-types";
import Links from '../../utils/links';

export default class ExploreCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
          title: props.title,
          content: props.content,
          image: props.image,
          links: props.links
        };

    }

    render() {
        return (
            <div className="explore-card-container">
                <div className="explore-card-info">
                    <span className="card-title">{this.state.title}</span>
                    <div className="card-text" dangerouslySetInnerHTML={{__html: this.state.content}}></div>
                    <div className="button-wrapper">
                        {this.state.links.map((link, key) => (
                            <span key={key}>
                                {Links.renderLink(link)}
                            </span>
                        ))}

                    </div>
                </div>
                <div className="explore-card-image-wrapper">
                    {this.state.image && (<img src={this.state.image} alt="Stylized medals." />)}
                </div>
            </div>
        );
    }

}


ExploreCard.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    links: PropTypes.array
};
import React, { Component } from 'react';

class Bullets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: []
        };
    }

    onToggle (index) {
        const isExpandedNew = [...this.state.isExpanded];
        isExpandedNew[index] = !isExpandedNew[index];
        this.setState({ isExpanded: isExpandedNew });
    }

    renderToggleButton (index) {
        return (
            <button onClick={() => this.onToggle(index)}>{this.state.isExpanded[index] ? '-' : '+'}</button>
        );
    }

    renderFirstSentance (text) {
        const endIdentifier = '. ';
        const sentenceText = text.split(endIdentifier);
        return sentenceText.length > 1 ? sentenceText[0] + endIdentifier : sentenceText;
    }

    renderMultilineText (bullet, index) {
        return (
            <>
                {
                    this.state.isExpanded[index] ? 
                        bullet.text
                        : this.renderFirstSentance(bullet.text)
                }
                {this.renderToggleButton(index)}
            </>
        );
    }

    renderBullet (bullet, index) {
        return (
                <li key={index} className="bullet">
                    { bullet.enable_read_more ? this.renderMultilineText(bullet, index) : bullet.text }
                </li>
        );
    }

    render () {
        return (
            <div>
                <h2>{this.props.heading}</h2>
                <ul className="bullets-container">
                    {this.props.bullets.map((bullet, index) => this.renderBullet(bullet, index))}
                </ul>
            </div>
        );
    }
}

export default Bullets;
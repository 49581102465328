import React, {Component} from 'react';
import Hero from './c2_hero';
import HeroIllustrated from './c2a_hero_illustrated';

export default class HeroSchool extends Component {

    getFirstYearButton = () => {
        const memberId = this.props.node.field_member_id_apply || this.props.node.field_member_id;
        return {
            title: 'Apply for first-year',
            alias: `https://apply.commonapp.org/login?ma=${memberId}&tref=3003`
        };
    };

    getTransferButton = () => {
        const memberIdTR = this.props.node.relationships.field_site_update.field_su_webadm_id || null;
        return {
            title: 'Apply for transfer',
            alias: memberIdTR
                ? `https://apply.transfer.commonapp.org/applicant-ux/?tref=3003#/deeplink/programSearch/organization/${memberIdTR}`
                : 'https://apply.transfer.commonapp.org/applicant-ux/?tref=3003'
        };
    };

    generateLinksForButtons = () => {
        let result = [];

        if (this.props.node.field_acc_md_fy_participation !== 'No') {
            result.push(this.getFirstYearButton());
        }

        if (this.props.node.field_acc_md_tr_participation !== 'No') {
            result.push(this.getTransferButton());
        }

        return result;
    }

    renderHeroWithImage = linksForButtons => {
        const heroProps = {
            "field_hero_title": this.props.node.title,
            "field_narrow_hero": true,
            "field_hero_text": '',
            "field_hero_school": true,
            "relationships": {
                "field_hero_image": this.props.node.relationships.field_site_update.relationships.field_school_hero,
            },
            "field_image_first_on_mobile": false,
            "links": linksForButtons
        };

        return  <Hero {...heroProps} />;
    };

    renderHeroIllustrated = linksForButtons => {
        const heroProps = {
            "field_hero_title": this.props.node.title,
            "field_narrow_hero": false,
            "field_hero_text": '',
            "field_illustration": 'member_profile',
            "links": linksForButtons
        };

        return <HeroIllustrated {...heroProps} />;
    };

    render = () => {
        const linksForButtons = this.generateLinksForButtons();
        const heroImage = this.props.node.relationships.field_site_update.relationships.field_school_hero;

        return (heroImage && heroImage.localFile)
           ? this.renderHeroWithImage(linksForButtons)
           : this.renderHeroIllustrated(linksForButtons);
    };
};
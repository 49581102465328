import React, {Component} from 'react';
import { graphql } from 'gatsby';


/**
 * This components quote statement
 */
export default class TermBox extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props)
    {
        super(props);
        this.data = props;
    }

    /**
     * Renders single term
     * @param key
     * @param item
     */
    renderItem(key, item)
    {
        return (
            <div className="term-box-component" key={key}>
                <strong>{item.field_title}</strong>
                <div dangerouslySetInnerHTML={{__html: item.field_text.processed}}></div>
            </div>
        )
    }


    /**
     * Render quote
     * @returns {*}
     */
    render()
    {
        return (
            <div>
                {this.data.relationships.field_items.map((item, key) => this.renderItem(key, item))}
            </div>
        )
    }
};

export const TermBoxQuery = graphql`
fragment ParagraphsTermBox on paragraph__term_box {
    relationships {
      field_items {
        field_title
        field_text {
          processed
        }
      }
    }
}
`;
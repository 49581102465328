import React, {Component} from 'react';
import {graphql} from "gatsby";


class AccordionItem extends Component {
    constructor() {
        super();
        this.state = {
            active: false
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            active: !this.state.active,
            className: "active"
        });
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.toggle();
        }
    };

    render() {
        const activeClass = this.state.active ? "active" : "inactive";
        const question = this.props.details;
        return (
            <div className={activeClass} onClick={this.toggle} onKeyDown={this._handleKeyDown} role="tabpanel" aria-label="Col" tabIndex="0">
                <span className="summary">{ `${question.field_title}`}</span>
                <div className="folding-pannel answer" dangerouslySetInnerHTML={{__html: question.field_text.processed}}></div>
            </div>
        );
    }
}

/**
 * This components accordion
 */
export default class Accordion extends Component {

    /**
     * Constuctor
     */
    constructor(props) {
        super(props);
        this.data = props;
    }

    /**
     *
     * @param key
     * @param item
     * @returns {*}
     */
    renderQuestion(key, item) {
        return <AccordionItem key={key} index={key} details={item}/>
    }

    /**
     *
     * @returns {*}
     */
    render() {
        return (
            <div aria-label="Questions">
                {this.data.field_title ? (<h2>{this.data.field_title}</h2>) : null}
                {this.data.field_subtitle ? (<p className="accordion-subtitle">{this.data.field_subtitle}</p>) : null}
                <div className="accordion-container" aria-label="Col" tabIndex="0">{this.data.relationships.field_cards.map((item, key) => this.renderQuestion(key, item))} </div>
            </div>
        )
    }
}

export const AccordionQuery = graphql`
fragment ParagraphsAccordion on paragraph__accordion {
    relationships {
      field_cards {
        field_title
        field_text {
          processed
        }
      }
    }
}
`;
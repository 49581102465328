import React, { Component } from 'react';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

/**
 * This component renders social share buttons
 */
class SocialMediaShare extends Component {

    calculateCurrentUrl()
    {
        if(typeof window === 'undefined')
            return '';

        return encodeURI(window.location);
    }

    /**
     * Render social share buttons
     */
    render () {
        const size = this.props.size || 20;
        return (
            <ul className="social-media-share">
                <li>
                    <a href={`https://facebook.com/sharer/sharer.php?u=${this.calculateCurrentUrl()}`} target="_blank" rel="noopener noreferrer"><FaFacebook size={size} title={"Facebook icon"} /></a>
                </li>
                <li>
                    <a href={`https://twitter.com/intent/tweet/?url=${this.calculateCurrentUrl()}`} target="_blank" rel="noopener noreferrer"><FaTwitter size={size} title={"Twitter icon"} /></a>
                </li>
                <li>
                    <a href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${this.calculateCurrentUrl()}`} target="_blank" rel="noopener noreferrer"><FaLinkedin size={size} title={"Linkedin icon"} /></a>
                </li>
            </ul>
        );
    }
}

export default SocialMediaShare;
import React, { Component } from 'react';
import Select from 'react-select';
import { pluralize } from "../../utils/pluralize";
import printIcon from "../../images/icons/icon-print-results.svg";
import sortingFunctions from "../../utils/sorting-functions";
import * as PropTypes from "prop-types";

/**
 * Header component with search functionality + autocomplete
 */
class ExploreResultsHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sortBy: 'name-asc',
            view: props.view,
        };
    }

    render() {
        const wrapperClass = this.props.view === 'grid' ? 'school-grid-container' : 'school-map-view-container background-gray';
        const headerClass = this.props.view === 'grid' ? 'school-grid-header container' : 'school-map-header container';

        return (
            <div className={wrapperClass} style={{ display: this.props.schools.length ? 'block' : 'none' }}>
                <div className={headerClass}>
                    <div>
                        <div className="search-results">{this.props.schools.length} {pluralize('college', this.props.schools.length)} found</div>
                        <div className="download-button-container">
                            <button style={{ visibility: this.props.schools.length ? 'visible' : 'hidden' }} onClick={() => this.onDownloadSearchResults()}>
                                <img src={printIcon} alt="Inbox" />
                                <span>Print search results</span>
                            </button>
                        </div>
                    </div>
                    <div className="sorting-container">
                        <span>Sort by</span>
                        {this.renderDropdown()}
                    </div>
                </div>
            </div>
        );
    }

    onDownloadSearchResults() {
        this.props.setPrintMode(true, () => this.startPrintMode());
    }

    startPrintMode () {
        window.print();
        // the line below will run after the user closes the print dialog
        this.props.setPrintMode(false);
    }

    onDropdownChange(value) {
        this.setState({ sortBy: value }, () => this.props.updateResults(this.props.schools));
    }

    sortResults() {
        return [...this.props.schools]
            .sort((currentSchool, nextSchool) => sortingFunctions[this.state.sortBy](currentSchool, nextSchool));
    }

    getSortBy()
    {
        return this.state.sortBy;
    }

    renderDropdown() {
        const options = [
            {
                value: 'name-asc',
                label: 'College name, A to Z'
            },
            {
                value: 'name-desc',
                label: 'College name, Z to A'
            }
        ];
        return (
            <Select
                value={this.state.sortBy}
                onChange={option => this.onDropdownChange(option.value)}
                options={options}
                placeholder={options.filter(option => option.value === this.state.sortBy)[0].label}
                aria-labelledby="sort-schools-label"
                className='react-select-container'
                classNamePrefix="react-select"
                isSearchable={false}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#F3F3F3',
                        // primary75: '#F3F3F3',
                        primary50: '#F3F3F3',
                        primary: '#F3F3F3'
                    },
                })}
                styles={(provided, state) => ({
                    ...provided,
                    'font-weight': state.isSelected ? 'bold' : 'normal',
                })} />
        );
    }

}

ExploreResultsHeader.propTypes = {
    schools: PropTypes.array,
    updateResults: PropTypes.func
};

export default ExploreResultsHeader;

import React, {Component} from 'react';
import { graphql } from 'gatsby';
import DangerouslySetHtmlContent from '../utils/set-inner-html';

/**
 * This components renders plain area text
 */
export default class PlainAreaText extends Component {

    /**
     * Constuct plain area text
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;

        this.state = {
            isExpanded: false
        };
    }

    onToggle () {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    renderIntro (introText) {
        return (
            <div className="plain-area-text-space" dangerouslySetInnerHTML={{ __html: introText  }}></div>
        );
    }

    renderMultilineText (introText) {
        return (
            <>
                {this.state.isExpanded ? this.renderText() : this.renderIntro(introText)}
                <button
                    onClick={() => this.onToggle()}
                    className={`plain-area-text-button ${this.state.isExpanded ? 'expanded' : ''}`}>
                    {this.state.isExpanded ? 'Read less' : 'Read more'}
                </button>
            </>
        );
    }

    renderText () {
        return this.data.field_text ?
            (<DangerouslySetHtmlContent className="plain-area-text-space" html={this.data.field_text.processed} allowRerender={true}></DangerouslySetHtmlContent>) :
            null;
    }

    renderContent () {
        if (!this.data.field_text) {
            return null;
        }
        const closingTagIdentifier = '</';
        const innerHTMLArray = this.data.field_text.processed.split(/<\/(h1|h2|h3|p|div)/i);
        const showReadMore = innerHTMLArray.length > 5;
        let introText = innerHTMLArray[0] + closingTagIdentifier + innerHTMLArray.pop();
        return this.data.field_enable_read_more && showReadMore ? this.renderMultilineText(introText) : this.renderText();
    }

    /**
     * Render plain area text
     * @returns {Object}
     */
    render () {
        return (
            <div className={`plain-area-text ${this.props.field_override_font_styling ? 'fixed-font-style' : ''}`}>
                {(this.data.field_title) ? (<h2>{this.data.field_title}</h2>) : null}
                {this.renderContent()}
            </div>
        );
    }
};

export const PlainAreaTextQuery = graphql`
fragment ParagraphsPlainAreaText on paragraph__plain_area_text {

  field_title
  field_text {
    processed
  }
  
}
`;
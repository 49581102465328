import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';
import downloadIcon from '../images/icons/icon-download.svg';
import JSZip from 'jszip';
import saveAs from 'file-saver';

/**
 * Wrapper for downloadable resource
 */
class DownloadableContent extends Component {

    /**
     * Class constructor
     * @param {Object} props
     */
    constructor(props) {
        super(props);
        this.state = {
            selectedLanguage: props.relationships.cards[0].language
        };
    }

    /**
     * Set selected language
     * @param {string} language
     */
    setSelectedLanguage (language) {
        this.setState({ selectedLanguage: language });
    }

    /**
     * Check is supplied language is selected
     * @param {string} language 
     * @returns {boolean}
     */
    isSelectedLanguage (language) {
        return language === this.state.selectedLanguage;
    }

    /**
     * Render language selection controls
     * @returns {Object}
     */
    renderLanguageSelection () {
        return (
            <ul className="language-list">
                {this.props.relationships.cards.map((card, index) => (
                    <li key={index} className="language-item">
                        <button
                            className={`language-pill ${this.isSelectedLanguage(card.language) ? 'language-pill--active' : ''}`}
                            type="button"
                            onClick={() => this.setSelectedLanguage(card.language)}>
                            {card.language}
                        </button>
                    </li>
                ))}
            </ul>
        );
    }

    /**
     * Downloads all resources for selected language
     */
    onDownloadAll () {
        const zip = new JSZip();
        const languagePack = this.props.relationships.cards.find(card => card.language === this.state.selectedLanguage);
        let numberOfFetchedResources = 0;
        languagePack.relationships.items.forEach(file => {
            window.JSZipUtils.getBinaryContent(file.relationships.field_file.localFile.publicURL, (error, data) => {
                if (error) {
                    // alert('There was an error downloading files.');
                }
                const extension = file.relationships.field_file.localFile.publicURL.split('.')[1];
                zip.file(`${file.filename}.${extension}`, data, { binary: true });
                if (++numberOfFetchedResources === languagePack.relationships.items.length) {
                    zip.generateAsync({type:"blob"}).then(content => saveAs(content, "resources.zip"));
                }
            });
        });
    }

    /**
     * Render button for downloading all resources for a language
     * @returns {Object}
     */
    renderDownloadAllButton () {
        const text = 'Download all resources';
        return (
            <div>
                <button className="download-button download-button--all" onClick={() => this.onDownloadAll()}>
                    <img src={downloadIcon} alt={text + ' with this button'} />
                    <span className="download-button-text">{text}</span>
                </button>
            </div>
        );
    }

    /**
     * Render last revised date
     * @param {string} fieldFile
     * @returns {Object}
     */
    renderLastRevisedDate (fieldFile) {
        return (fieldFile && fieldFile.changed) ?
            `Last revised ${moment(fieldFile.changed).format('MM/DD/YY')}` :
            null;
    }

    renderDownloadButton (href, text, fileName) {
        //  download={fileName}
        return (
            <a className="download-button" href={href} target="_blank" rel="noopener noreferrer">
                <img src={downloadIcon} alt={text  + ' with this button'} />
                <span className="download-button-text">{text}</span>
            </a>
        );
    }

    /**
     * Render image
     * @param {Object} image
     * @returns {Object}
     */
    renderImage(image, description) {
        return (image && image.localFile && image.localFile.childImageSharp)  ?
            (<GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={description} />) :
            null;
    }

    /**
     * Render a single downloadable resource
     * @param {Object} file
     * @param {number} key
     * @returns {Object}
     */
    renderSingleDownloadableResource(file, key) {
        let titleName = 'downloadable-item-title';
        if(!file.relationships.field_image){
            titleName += ' without-image';
        }

        let classes = 'downloadable-item'
        
        if (this.props.field_background_color === 'Teal') {
            classes += ' card-teal'
        } else if (this.props.field_background_color === 'Blue') {
            classes += ' card-blue'
        }        

        return (
            <li className={classes} key={key}>
                <div className="downloadable-item-content">
                    <p className={titleName}>{file.filename}</p>
                    {file.filetype ? <span className="downloadable-item-filetype">{file.filetype}</span> : ''}

                    { (file.relationships.field_file && file.relationships.field_file.localFile) ? (
                        <div className="downloadable-item-bottom-row">
                            <p className="downloadable-item-revision-date">{this.renderLastRevisedDate(file.relationships.field_file)}</p>
                            {this.renderDownloadButton(file.relationships.field_file.localFile.publicURL, file.download_text, file.filename)}
                        </div>
                    ) : null}
                </div>
                <div className="downloadable-item-image">{this.renderImage(file.relationships.field_image, file.filename)}</div>
            </li>
        );
    }

    /**
     * Render all downloadable resources for a single language
     * @param {Object} card
     * @param {number} index
     * @returns {Object}
     */
    renderDownloadableResourcesPerLanguage(card, index) {
        return this.isSelectedLanguage(card.language) && (
            <ul className="downloadable-items" key={index}>
                {card.relationships.items.map((file, index) => this.renderSingleDownloadableResource(file, index))}
            </ul>
        );
    }

    /**
     * Render all downloadable resources for all languages
     * @returns {Object}
     */
    renderAllDownloadableResources () {
        return (
            <div>
                {this.props.relationships.cards.map((card, index) => this.renderDownloadableResourcesPerLanguage(card, index))}
            </div>
        );
    }

    /**
     * Render downloadable resources
     * @returns {Object}
     */
    render() {
        return (
            <div className="downloadable-content">
                <div className="downloadable-content-header-wrapper">
                    <h2 className="downloadable-content-header">Resources</h2>
                    <div className="downloadable-content-header-content">
                        {this.props.relationships.cards.length > 1 ? this.renderLanguageSelection() : null}
                        {this.renderDownloadAllButton()}
                    </div>
                </div>
                {this.renderAllDownloadableResources()}
            </div>
        );
    }
}

export const DownloadableContentQuery = graphql`
    fragment ParagraphsDownloadableContent on paragraph__downloadable_content {
        field_background_color
        relationships {
            cards:field_items {
                language: field_title
    
                relationships {
                    items:field_items {
                    
                        # File meta
                        filename:field_title
                        filetype:field_tagline
                        download_text:field_ec_search_text
                        
                        relationships {
                            # Placeholder image
                            field_image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: FIXED)
                                    }
                                }
                            }
                            
                            # File
                            field_file {
                                changed
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

DownloadableContent.propTypes = {
    relationships: PropTypes.object
};

export default DownloadableContent;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import YouTube from './you-tube';
import Wistia from './wistia';

class VideoProvider extends Component {
  static propTypes = {
    videoUrl: PropTypes.string.isRequired,
    shouldPlay: PropTypes.bool,
    shouldStop: PropTypes.bool,
    hasOverlay: PropTypes.bool,
    tabIndex: PropTypes.number
  };

  getWistiaId = () => this.props.videoUrl.split('/').pop();

  getYouTubeId = () => {
    const videoPath = this.props.videoUrl
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)[2];
    return videoPath !== undefined
      ? videoPath.split(/[^0-9a-z_]/i)[0]
      : this.props.videoUrl;
  };

  render = () => this.props.videoUrl.includes('wistia')
    ? <Wistia videoId={this.getWistiaId()} {...this.props} />
    : <YouTube videoId={this.getYouTubeId()} {...this.props} />;
}

export default VideoProvider;
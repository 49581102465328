import React, {Component} from 'react';
import { graphql } from 'gatsby';

import Slider from "react-slick";


export default class HowItWorks extends Component {

	constructor(props) {
		super(props);
		this.data = props;
		this.state = {
          mobileView: false
        };
	}

	componentDidMount () {
        this.getViewportWidth();
        window.addEventListener('resize', this.getViewportWidth);
    }
    
    componentWillUnmount () {
        window.removeEventListener('resize', this.getViewportWidth);
    }

    getViewportWidth = () => {
      const innerWidth = Math.min(window.innerWidth, window.screen.width);
      const mobileView = innerWidth < 768;
      this.setState({ mobileView });
    };

	renderStep(step, counter)
	{
		return (
			<div className="col-steps" key={counter}>
				{this.props.field_disable_counter? null : <h3>{counter}</h3>}
				<h4>{step.field_step_title}</h4>
				<span dangerouslySetInnerHTML={{__html: step.field_step_body.processed}}></span>
			</div>
		)
	}

	renderSlider (){
		var settings = {
          dots: true,
          infinite: true,
          speed: 500,
          arrows: false,
          useCss: false,
          slidesToShow: 1, 
          slidesToScroll: 1,
          variableWidth: false,
          swipeToSlide: true
        };
        if (this.state.mobileView) {
        	return (
				<div className="parrent">
					<Slider {...settings}>
						{ this.data.relationships.field_steps.map((component,idx) => this.renderStep(component, idx+1)) }
					</Slider>
				</div>
			)
        } else {
        	return (
        		<div className="row">
					{ this.data.relationships.field_steps.map((component,idx) => this.renderStep(component, idx+1)) }	
				</div>
        	)
        }
        
	}


	render() {
		let containerClass = "container how-it-works"
		if (this.props.field_background_color === 'Green') {
            containerClass += ' card-green'
        } else if (this.props.field_background_color === 'Blue') {
            containerClass += ' card-blue'
        } 
		return (
			<section className={containerClass}>
				<div className="inner">
					<div className="row">
						<div className="col-sm-12">
							<h2>{this.data.field_how_it_works_title}</h2>
						</div>
					</div>
					{this.renderSlider()}
				</div>
			</section>
		)
	}
};


export const HowItWorksQuery = graphql`
fragment ParagraphHowItWorks on paragraph__how_it_works {
  
  field_how_it_works_title
  field_background_color
  field_disable_counter
  
  relationships {
	field_steps {
	  field_step_title
	  field_step_body {
		processed
	  }
	}
  }
}`;
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import SocialMediaShare from './internal/social-media-share';

/**
 * This components renders blog post header
 */
export default class BlogPostHeader extends Component {

    /**
     * Render blog post header
     * @returns {Object}
     */
    render() {
        return (
            <section className="container blog-post-header-container">
                <div className="col-lg-8 offset-lg-2">
                    <h1>{this.props.node.title}</h1>
                    <SocialMediaShare size={16} />
                    <div className="publishing-author">By {this.props.node.field_blog_author}</div>
                    <div className="publishing-date">{this.props.node.created_formatted}</div>
                </div>
                <Helmet>
                    <title>{this.props.node.title}</title>
                </Helmet>
            </section>
        )
    }
};
import React, {Component} from 'react';
import { graphql, navigate } from 'gatsby';
import axios from 'axios';
import { SUBSCRIBE } from '../constants/url';
export default class NewsletterSignup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: '',
			status: null
		};
		this.data = props;
	}

	handleChange (event) {
		this.setState({ email: event.target.value });
	}

	onSignUp (event) {
		event.preventDefault();
		const data = `email=${this.state.email}`;
		const headers = {
			'Content-Type': 'application/x-www-form-urlencoded'
		};
		axios.post(window.backendUrl + SUBSCRIBE, data, headers)
			.then(response => {
				if (response.data.message.endsWith('is subscribed!')) {
					this.setState({ status: 'subscribed' });
				} else if (response.data.message.includes('is already a list member')) {
					this.setState({ status: 'already-registered' });
				} else {
					return Promise.reject();
				}
			})
			.catch(() => this.setState({ status: 'failure' }))
			.finally(() => this.setState({ email: '' }));
	}

	renderStatus () {
		if (this.state.status) {
			switch (this.state.status) {
				case 'already-registered':
					return `This email is already registered for the newsletter.`;
					break;
				case 'subscribed':
					return 'You have been signed up for the Counselor newsletter.';
					break;
				default:
					return `Something went wrong. Please try again.`;

			}
			// return this.state.status === 'already-registered' ?
			// 	`This email is already registered for the newsletter.` :
			// 	`Something went wrong. Please try again.`;
		} else {
			return null;
		}
	}

	render() {
		return (
			<section className="newsletter-content">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h2>{this.data.field_title}</h2>
							<div dangerouslySetInnerHTML={{__html: this.data.field_text.processed }}></div>
						</div>
					</div>
					<form className="row" onSubmit={event => this.onSignUp(event)} aria-label="Sign up on newsletter">
						<div className="col-lg-12">
							<span className="sign-up-status">{ this.renderStatus() }</span>
						</div>
						<div className="col-xs-12 col-sm-12 col-lg-10">
							<input
								onChange={event => this.handleChange(event)}
								type="email"
								required
								value={this.state.email}
								className="form-control"
								placeholder="Your email"
								aria-label="Your email" />
						</div>
						<div className="col-xs-12 col-sm-4 col-lg-2">
							<button type="submit" className="btn btn-lg btn-dark">Sign up</button>
						</div>
					</form>
				</div>
			</section>
		)
	}
};


export const NewsletterSignupQuery = graphql`
fragment ParagraphNewsletterSignup on paragraph__newsletter_signup {

  field_title
  field_text {
  	processed
  }

}`;

import React, {Component} from 'react';
import {graphql, Link} from 'gatsby';


/**
 *
 */
export default class SingleCardGradientBorderB extends Component {


	/**
	 * Component constructor
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.data = props;
	}

	/**
	 *
	 * @param key
	 * @param link
	 * @returns {*}
	 */
	button(key, link)
	{
		const classes = 'btn btn-dark btn-sm btn-go';

		// Is link external or internal? If it starts with slash ( / ), its internal
		const internal = /^\/(?!\/)/.test(link.alias);

		if (internal) {
			return (
				<Link className={classes} key={key} to={link.alias}>{link.title}</Link>
			);
		}

		return (
			<a href={link.alias} className={classes} key={key}>{link.title}</a>
		);
	}

	renderImage()
    {

        if(this.data && this.data.relationships && this.data.relationships.field_image && this.data.relationships.field_image.localFile){
            return (<img height='80' src={this.data.relationships.field_image.localFile.publicURL} alt="icon" />);
        }
        return '';
        
    }

	/**
	 * Component renderer
	 * @returns {*}
	 */
	render() {
		return (
			<section className="one-block with-label">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-inner col-lg-8 col-xl-8">
							<div className="inner">
								{this.renderImage()}
								<span className="label" dangerouslySetInnerHTML={{__html: this.data.field_tagline}}></span>
								<h2 dangerouslySetInnerHTML={{__html: this.data.field_title}}></h2>
								{this.data.links.map((link, i) => this.button(i, link))}
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}

};

export const SingleCardGradientBorderBQuery = graphql`
fragment ParagraphsSingleCardGradientBorderB on paragraph__single_card_gradient_border_b {
  field_title
  field_tagline
  
  links {
    alias
    title
    fieldname
  }
  
  relationships {
	field_image {
	  localFile {
          publicURL
	  }
	}
  }
}
`;



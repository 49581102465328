import React, {Component} from 'react';
import { graphql } from 'gatsby';
import Links from '../utils/links';

import bottomBg from'../images/icons/illustration-your-path-to-college.svg';
import airBalloon from'../images/icons/air-balloon-illustration.svg';


/**
 * This components renders hero
 */
export default class BlueBackgroundText extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props)
    {
        super(props);
        this.data = props;

    }

    renderButton()
    {
        if(this.data.links.length === 0)
        {
            return null;
        }

        const link = this.data.links[0];

        return (
            Links.renderLink(link, "btn btn-lg btn-white")
        )
    }



    /**
     * Render menu
     * @returns {*}
     */
    render()
    {
        let classes = "path-college"
        if(this.data.field_without_lineart_images) {
          classes += " no-image"
        }
        if(this.props.field_gradient_color === 'Teal') {
          classes+= " gradient-teal"
        } else if (this.props.field_gradient_color === 'Navy') {
            classes+= " gradient-navy"
        }
        return (
          <section className={classes}>
            <div className="container">
            {
              !this.data.field_without_lineart_images && (
                <img className="balloon-icon" src={airBalloon} alt="Air Balloon" />
              )
            }
              <div className="row">
                <div className="col-sm-12 col-lg-8">
                    {(this.data.field_title) ? (<h2>{this.data.field_title}</h2>) : null}
                    {(this.data.field_text) ? (<div className="headline-text" dangerouslySetInnerHTML={{ __html: this.data.field_text.processed  }} />) : null }
                    {this.renderButton()}
                </div>
              </div>
            </div> 
            {
              !this.data.field_without_lineart_images && (
              <div className="bottom-bg-holder">
                <img className="bottom-bg" src={bottomBg} alt="Bg" />
              </div>
              )
            }
          </section>
        )
    }
};

export const BlueBackgroundTextQuery = graphql`
fragment ParagraphsBlueBackgroundText on paragraph__blue_background_text {

  field_title
  field_gradient_color
  field_without_lineart_images
  field_text {
    processed
  }
  links {
    alias
    title
    fieldname
  }
  
}
`;
import React, {Component} from 'react';
import {graphql} from 'gatsby';

import manPlant from '../images/icons/hero/man-plant.svg';
import buildingSun from '../images/icons/hero/building-sun.svg';

import gradeIllustration from '../images/icons/hero/illustration-grades.svg';
import iconAirBalloon from '../images/icons/hero/air-balloon-illustration.svg';

import image1 from '../images/icons/hero/image-1-icon.png';
import image2 from '../images/icons/hero/commonapp_ready_toolbox.svg';

import image4 from '../images/icons/hero/image4-icon.png';

import careers1 from '../images/icons/hero/careers-1.svg';
import careers2 from '../images/icons/hero/careers-2.svg';
import careers3 from '../images/icons/hero/careers-3.svg';

import help1 from '../images/icons/hero/help-1.svg';
import help2 from '../images/icons/hero/help-2.svg';

import middleSchool from '../images/icons/hero/illustration-hero-middle-school.svg';

import communityCollege from '../images/icons/hero/illustration-community-college.svg';

import serviceMembersAndVeteransIcon from '../images/icons/hero/illustration-service-members-combined.svg';

import grade10th from '../images/icons/hero/illustration-10th-grade.svg';

import grade11th from '../images/icons/hero/illustration-11th-grade.svg'

import grade12th from '../images/icons/hero/illustration-12th-grade.svg';

import guyOnTrack from '../images/icons/hero/illustration-guy.svg';
import girlOnTrack from '../images/icons/hero/illustration-girl-bench.svg';

import transferBus from '../images/icons/hero/illustration-bus.svg';

import buildingSunMemberToolkit from '../images/icons/hero/illustration-building-sun.svg';
import planeTreeHouse from '../images/icons/hero/illustration-building-toolbox.svg';

import womanRecommenderGuide from '../images/icons/hero/woman-folder.svg';
import manSignCombined from '../images/icons/hero/illustration-man-sign-combined.svg';


import iconCollege from '../images/icons/hero/illustration-default-member-profile.svg';
import iconCollegeMobile from '../images/icons/hero/illustration-default-member-profile-mobile.svg';

import blueDog from '../images/icons/hero/blue-dog.svg'
import blueMailbox from '../images/icons/hero/blue-mailbox.svg'
import blueSun from '../images/icons/hero/blue-sun.svg'
import blueTrees from '../images/icons/hero/2-blue-trees.svg'

import skyGuitar from '../images/icons/hero/sky-guitar.svg'
import skyBookStack from '../images/icons/hero/sky-book-stack.svg'
import skyPersonWheelchair from '../images/icons/hero/sky-person-wheelchair.svg'

import pineTreeTeal from '../images/icons/hero/pine-tree-teal.svg'
import tealClockTower from '../images/icons/hero/teal-clock-tower.svg'
import tealDomedBuilding from '../images/icons/hero/teal-domed-building.svg'
import tealCloud from '../images/icons/hero/teal-cloud.svg'

/**
 * This components renders hero
 */
export default class HeroIllustrated extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props)
	  {
        super(props);
        this.data = props;
    }

    /**
     * Renders illustrations
     * @returns {*}
     */
    renderIllustrations()
    {
        if(!this.data.field_illustration)
            return null;


        switch (this.data.field_illustration) {

            case "common_app_grades":
                return (
                    <>
                        <img src={gradeIllustration} className="building-house-icon" alt="grade illustration" />
                        <img src={iconAirBalloon} className="man-balloon-icon" alt="man balloon icon" />
                    </>
                );

            case "common_app_ready":
                return (
                    <>
                        <img src={image1} className="image1-icon" alt="man plant icon" />
                        <img src={image2} className="image2-icon" alt="building sun icon" />
                    </>
                );

            case "common_app_insight":
                return (
                    <>
                        <img src={image4} className="image4-icon" alt="icon" />
                    </>
                );

            case "careers":
                return (
                    <>
                        <img src={careers1} className="careers1-icon" alt="icon" />
                        <img src={careers2} className="careers2-icon" alt="icon" />
                        <img src={careers3} className="careers3-icon" alt="icon" />
                    </>
                );

            case "help":
                return (
                    <>
                        <img src={help1} className="help1-icon" alt="help 1 icon" />
                        <img src={help2} className="help2-icon" alt="help 2 icon" />
                    </>
                );

            case "common_app_middle_school":
                return (
                    <>
                        <img src={middleSchool} className="grades-middle" alt="middle school" />
                    </>
                );

            case "common_app_community_college":
                return (
                    <>
                        <img src={communityCollege} className="community-college" alt="community college" />
                    </>
                );

            case "member_profile":
                return (
                    <>
                        <img src={iconCollege} className="icon-college" alt="college" />
                        <img src={iconCollegeMobile} className="icon-college-mobile" alt="college" />
                    </>
                );

            case "common_app_service_members_and_veterans":
                return (
                    <>
                        <img src={serviceMembersAndVeteransIcon} className="veterans" alt="service members and veterans flag" />
                    </>
                );

            case "common_app_9th_grade":
                return (
                    <>
                        <img src={gradeIllustration} className="building-house-icon" alt="grade illustration" />
                        <img src={iconAirBalloon} className="balloon" alt="man balloon icon" />
                    </>
                );

            case "common_app_10th_grade":
                return (
                    <>
                        <img src={grade10th} className="grades-10th" alt="10th grade" />
                    </>
                );

            case "common_app_11th_grade":
                return (
                    <>
                        <img src={grade11th} className="grades-11th" alt="11th grade" />
                    </>
                );

            case "common_app_12th_grade":
                return (
                    <>
                        <img src={grade12th} className="grades-12th" alt="12 grade" />
                    </>
                );

            case "common_app_on_track":
                return (
                    <>
                        <img src={guyOnTrack} className="common-app-track-1" alt="Common app on track guy" />
                        <img src={girlOnTrack} className="common-app-track-2" alt="Common app on track girl" />
                    </>
                );

            case "common_app_for_transfer_students":
                return (
                    <>
                        <img src={transferBus} className="transfer-bus" alt="Common app for transfer students bus" />
                    </>
                );

            case "common_app_why_college_matters":
                return (
                    <>
                        <img src={manPlant} className="man-plant-icon" alt="man plant icon" />
                        <img src={buildingSun} className="building-sun-icon" alt="building sun icon" />
                    </>
                );

            case "common_app_member_toolkit":
                return (
                    <>
                        <img src={buildingSunMemberToolkit} className="member-toolkit-sun" alt="building sun" />
                        <img src={planeTreeHouse} className="member-toolkit-tree" alt="plane tree house" />
                    </>
                );

            case "common_app_recommender_guide":
                return (
                    <>
                        <img src={womanRecommenderGuide} className="recommender-guide-woman" alt="woman commonapp" />
                        <img src={manSignCombined} className="recommender-guide-man" alt="man sign combined" />
                    </>
                );

            case "dog_postbox_tree_sun":
                return (
                    <>
                        <img src={blueDog} alt="An illustration of a dog wearing a backpack." className="blue-dog" />
                        <img src={blueMailbox} alt="An ill mail box." className="blue-mailbox" />
                        <img src={blueSun} alt="An illustration of the sun." className="blue-sun" />
                        <img src={blueTrees} alt="An illustration of two trees." className="blue-trees" />
                    </>
                )

            case "guitar_lady_desk":
                return (
                    <>
                        <img src={skyGuitar} alt="An illustration of a guitar." className="sky-guitar" />
                        <img src={skyBookStack} alt="An illustration of a stack of books." className="sky-bookstack" />
                        <img src={skyPersonWheelchair} alt="An illustration of lady on a wheelchair using her laptop." className="sky-person-wheelchair" />
                    </>
                )

            case "clocktower_building":
                return (
                    <>
                        <img src={pineTreeTeal} alt="An illustration of a pine tree." className="teal-pine-tree" /> 
                        <img src={tealClockTower} alt="An illustration of a clock tower" className="teal-clock-tower" /> 
                        <img src={tealDomedBuilding} alt="An illustration of a building with a domed shape roof." className="teal-domed-building" />
                        <img src={tealCloud} alt="An illustration of a cloud" className="teal-cloud" />
                    </>
                )

            default:
                return (
                    <>
                        <img src={manPlant} className="man-plant-icon" alt="man plant icon" />
                        <img src={buildingSun} className="building-sun-icon" alt="building sun icon" />  
                    </>
                );
        }

    }

    renderHeroApplyButton (link, index) {
        return (
            <a key={index} className="btn btn-c2a btn-lg btn-blue hero-button-apply" rel="noopener noreferrer" href={link.alias}>{link.title}</a>
        );
    }

    /**
     * Renders illustration links
     * @returns {*}
     */
    renderLinks() {
        return this.data.links && this.data.links.length ?
            <div className="content-wrapper hero-button-container">
                {
                    this.data.links.map((link, index) => this.renderHeroApplyButton(link, index))
                }
            </div> :
            null;
    }


    renderText (){
        const textHero = this.data.field_hero_text;
        if(textHero){
          return (<p>{textHero}</p>);
        } else {
          return '';
        }
    }

    renderHeading () {
        return this.props.field_is_this_the_mid_page_hero? <h2>{this.data.field_hero_title}</h2> : <h1>{this.data.field_hero_title}</h1>  
    }

    /**
     * Render menu
     * @returns {*}
     */
    render()
    {

        let classes = 'hero-illustration' + ((this.data.field_narrow_hero) ? ' hero-narrow' : '');

        if(this.data.field_illustration === 'common_app_ready') {
          classes += ' hero-blue';
        } else if (this.data.field_illustration === 'common_app_insight') {
          classes += ' hero-purple';
        } else if (this.data.field_illustration === 'common_app_on_track') {
            classes += ' hero-green';
        } else if(this.data.field_illustration === "dog_postbox_tree_sun") {
            classes += ' hero-dog-illustration-blue'
        } else if(this.data.field_illustration === "guitar_lady_desk") {
            classes += ' hero-guitar-illustration-skyblue'
        } else if(this.data.field_illustration === "clocktower_building") {
            classes += ' hero-clocktower-illustration-teal'
        }

        if(!this.data.field_hero_text) {
            if(!this.data.field_illustration){
              classes += " hero-only-title";
            } else {
              classes += " hero-with-icons";
            }   
        }
        if(this.data.field_remove_gradient) {
            classes += ' hero-no-gradient'
        }
        if(this.data.field_remove_horizontal_line) {
            classes += ' hero-no-bottom-ruler'
        }

        const classNames = this.data.field_illustration === 'member_profile' ? "col content-wrapper member-profile-illustration" : "col col-lg-8 col-xl-7 offset-lg-2 content-wrapper";

        return (
            <section className={classes} aria-label="Main section on page">
                <div className="container">
                    <div className="row">
                        <div className={classNames}>
                            { this.renderHeading() }
                            { this.renderText() }
                            { this.renderLinks() }
                        </div>
                    </div>
                    { this.renderIllustrations() }
                </div>
            </section>
        )
    }
};

export const HeroIllustratedQuery = graphql`
fragment ParagraphHeroIllustrated on paragraph__hero_illustrated {
  field_hero_title
  field_hero_text
  field_illustration
  field_narrow_hero
  field_is_this_the_mid_page_hero
  field_remove_gradient
  field_remove_horizontal_line
  links {
    alias
    title
  }
}`;
import React, {Component} from 'react';
import {graphql} from 'gatsby';


/**
 * This components quote statement
 */
export default class QuoteStatement extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;
    }

    resolveText() {
        if (this.data.field_text !== null && this.data.field_text.processed !== null) {
            return this.data.field_text.processed;
        }
        return '';
    }


    /**
     * Render quote
     * @returns {*}
     */
    render() {

        return (
            <>

              <div className="quote-wrapper">
                {this.data.field_title && (<h2>{this.data.field_title}</h2>)}

                <blockquote className={"quote-statement"}>
                  <div className="wrapper">
                    <div className="statement" dangerouslySetInnerHTML={{__html: this.resolveText()}}>
                    </div>

                    {(this.data.field_tagline) ? (
                        <footer>
                            <cite>{this.data.field_tagline}</cite>
                        </footer>
                    ) : null}
                  </div>
                </blockquote>
              </div>
            </>
        )
    }
};

export const QuoteStatementQuery = graphql`
fragment ParagraphsQuoteStatement on paragraph__quote_statement {
  
  field_title
  field_tagline
  
  field_text {
    processed
  }
  
}
`;
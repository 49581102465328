import React, { Component } from 'react';
import { navigate } from 'gatsby';

import exploreFilters from '../explore/explore-filters';
import generateFilters from './../explore/generate-filters';

class Pills extends Component {
    onClick = tag => {
        const pill = exploreFilters.map((filterGroup) => filterGroup.controls).reduce((acc, filter) => acc.concat(filter)).find(pill => pill.name === tag);
        
        const params = new URLSearchParams();
		params.set('filter', btoa(JSON.stringify(generateFilters([pill]))));
		navigate(`/explore/?${params.toString()}`);
    }

    render = () => (
        <div className="pill-container">
            {this.props.pills.map((pill, index) => (
                <button onClick={() => this.onClick(pill)} key={index} className="pill">{pill}</button>
            ))}
        </div>
    )
}

export default Pills;
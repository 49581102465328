import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { FaImage } from 'react-icons/fa';

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
          photoIndex: 0,
          isOpen: false,
        };

    }

    

    render () {
        const { photoIndex, isOpen } = this.state;

        return (
            <div className="gallery-content">
                
                <div className="placeholder-img">
                    <img src={this.props.images[0]} alt="school" />
                    
                    <button type="button" className="btn btn-sm btn-transparent" onClick={() => this.setState({ isOpen: true })}>
                        <FaImage title={"Image icon"} />View image gallery
                    </button>
                </div>

                {isOpen && (
                  <Lightbox
                    mainSrc={this.props.images[photoIndex]}
                    nextSrc={this.props.images[(photoIndex + 1) % this.props.images.length]}
                    prevSrc={this.props.images[(photoIndex + this.props.images.length - 1) % this.props.images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + this.props.images.length - 1) % this.props.images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % this.props.images.length,
                      })
                    }
                  />
                )}
                
            </div>
        );
    }
}

export default Gallery;
import React, {Component} from 'react';
import {graphql, navigate} from 'gatsby';
import Select from 'react-select';
import axios from 'axios';
import { CONTACT } from '../constants/url';

/**
 * This components renders hero
 */
export default class ContactForm extends Component {

    state = {
        status: '',
        subject: '',
        fullname: '',
        email: '',
        message: '',
        responseFromBackend: ''
    }

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;

        this.options = this.data.field_subjects?.map((subject) => {
            return {
              value: subject.split('|')[0],
              label: subject.split('|')[1]
            };
        } );
    }

    onSubmit (event) {
        event.preventDefault();
        if (!this.state.subject) {
            return;
        }
        const data = `email=${[this.state.email]}&id=${this.state.subject.value}&subject=${this.state.subject.label}&message=${this.state.message}&full_name=${this.state.fullname}`;
        const headers = {
			'Content-Type': 'application/x-www-form-urlencoded'
		};
        



        axios.post(window.backendUrl + CONTACT, data, headers)
            .then(response => {
              if (response.data.message.endsWith('is subscribed!')) {
                navigate('/contact-confirmation');
                this.setState({ status: 'success' });
              } else {
                return Promise.reject(this.setState({ responseFromBackend: response.data.message }));
              }
            })
            .catch(() => this.setState({ status: 'failure' }))
            //.finally(() => console.log('contact submitted'))


	  }

    /**
     * Handles input changes
     * @param changed
     * @param key
     */
    handleChange = (changed, key) => {
        const value = key === 'subject' ? changed : changed.target.value;
        this.setState({ [key]: value });
    }

    renderStatus () {
		if (this.state.status) {
			return this.state.status === 'success' ?
				`Thank you for contacting Common App. A member of our team will be in touch with you soon.` :
				this.state.responseFromBackend;
		} else {
			return null;
		}
	}

    /**
     * Render menu
     * @returns {*}
     */
    render() {
        return (
            <section className="container contact-form">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">

                        <form onSubmit={(event) => this.onSubmit(event)}>
                            <div className="form-group">
                                <label id="subject-label">Subject</label>

                                <Select
                                    value={this.state.subject}
                                    onChange={event => this.handleChange(event, 'subject')}
                                    options={this.options}
                                    placeholder="Select"
                                    aria-labelledby="subject-label"
                                    className='react-select-container'
                                    classNamePrefix="react-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#F3F3F3',
                                            // primary75: '#F3F3F3',
                                            primary50: '#F3F3F3',
                                            primary: '#F3F3F3'
                                        },
                                    })}
                                    styles={(provided, state) => ({
                                        ...provided,
                                        'font-weight': state.isSelected ? 'bold' : 'normal',
                                    })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="fullname">Full name</label>
                                <input type="text"
                                       name="fullname"
                                       className="form-control"
                                       id="fullname"
                                       placeholder=""
                                       required
                                       value={this.state.fullname}
                                       onChange={event => this.handleChange(event, 'fullname')} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email address</label>
                                <input type="email"
                                       name="email"
                                       className="form-control"
                                       id="email"
                                       placeholder=""
                                       required
                                       value={this.state.email}
                                       onChange={event => this.handleChange(event, 'email')} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Body</label>
                                <textarea
                                    className="form-control"
                                    id="message"
                                    rows="8"
                                    required
                                    value={this.state.message}
                                    onChange={event => this.handleChange(event, 'message')} />
                            </div>

                            <div className="legal" dangerouslySetInnerHTML={{__html: this.data.field_text?.processed}} />

                            <div className="mt-5">
                                <div className="sign-up-status">{ this.renderStatus() }</div>
                                <button type="submit" className="btn btn-lg btn-primary btn-blue">
                                    Submit
                                </button>
                            </div>
                        </form>


                    </div>
                </div>
            </section>
        )
    }
};

export const ContactFormQuery = graphql`
fragment ParagraphsContactForm on paragraph__contact_form {

  field_text {
    processed
  }
  field_subjects
  
}
`;

const filterByAudience = (blogPost, state) => state.audiences.some(audience => audience.isActive) ?
    state.audiences.some(audience => audience.isActive && audience.id === blogPost.relationships.field_blog_audience.id) :
    blogPost;

const filterByTag = (blogPost, state) => state.tags.some(tag => tag.isActive) ?
    state.tags.some(stateTag => stateTag.isActive && blogPost.relationships.field_blog_tags.some(blogTag => blogTag.id === stateTag.id)) :
    blogPost;

const filterByStartDate = (blogPost, state) => state.startDate ?
    state.startDate.isBefore(blogPost.created) :
    blogPost;

const filterByEndDate = (blogPost, state) => state.endDate ?
    state.endDate.isAfter(blogPost.created) :
    blogPost;

const blogFilterCallbacks = [filterByAudience, filterByTag, filterByStartDate, filterByEndDate];

export default blogFilterCallbacks;

import React, { Component } from 'react';
import ComponentsManager from '../utils/components-manager';
import School from '../utils/school';
import Links from '../utils/links';

/**
 * Utilize builder pattern to build out school page out of existing or specialized components.
 * Because data needs to be synchronized with salesforce, we're using plain fields instead of components on drupal
 * and we build out components directly on front end
 */
export default class SchoolComponent extends Component {

    /**
     * Holds components data to render school fully
     * @type {Array}
     */
    componentsData = [];

    /**
     * Holds school model class instance
     * @type {School}
     */
    school = null;

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;

        // School model is used to fetch information about this school
        this.school = new School(this.data.node);

        // Build out page
        this.addIntroduction()
            .addTags();

        // If page has virtual tour
        if(this.school.getIpeds() && !this.school.isYouVisitSuppressed())
        {
            this.addVirtualTour()
                .addAcademicProgramsTags()
                .addStudentExperienceTags()
                .addApplicationInformation()
                .addGallery();

        // If page has no virtual tour, gallery is at top
        } else {
            this.addGallery()
                .addAcademicProgramsTags()
                .addStudentExperienceTags()
                .addApplicationInformation();
        }

        this.addAfterApplying()
            .addVisitUs()
            .addQuote();


    }

    /**
     * Render school component
     * @returns {*}
     */
    render() {
        // School entity: {this.data.node.field_school_account_id}
        // Membership profile entity: {this.data.node.relationships.field_membership_profile.field_mp_website}
        // Site Update profile entity: {this.data.node.relationships.field_site_update.field_su_twitter}
        return (
            <div className="school-container">
                <div className="school-inner">
                    <ComponentsManager components={this.componentsData} />
                </div>
            </div>
        )
    }

    /**
     * Add Virtual Tour
     */
    addVirtualTour()
    {
        const raw = this.school.getIpeds();

        //console.log("[SCHOOL] Virtual Tour", raw);

        if(!raw)
        {
            return this;
        }

        const component = {
            ipeds: raw,
            __typename: 'paragraph__virtual_tour'
        };

        this.componentsData.push(component);

        return this;
    }
    /**
     * Add Gallery
     */
    addGallery()
    {
        const raw = this.school.getGalleryImages();
        //console.log("[SCHOOL] Gallery", this.school.getGalleryImages());

        if(!raw || this.school.isGalleryEmpty())
        {
            return this;
        }

        const component = {
            heading: 'Gallery',
            images: raw,
            __typename: 'paragraph__gallery'
        };

        this.componentsData.push(component);

        return this;
    }

    /**
     * Add introduction text block
     * @returns {SchoolComponent}
     */
    addIntroduction()
    {
        // Separate text by new line
        // and wrap it with appropriate styling tags

        const raw = this.data.node.relationships.field_site_update.field_su_tb_about_us;
        //console.log("[SCHOOL] Introduction text", raw);

        if(!raw)
            return this;

        const textSplit = raw.split(/\r\n|\r|\n/);
        let introductionProcessed = '';
        textSplit.map((textChunk) => introductionProcessed += `<h2 class="headline-text">`+Links.wrapURLs(textChunk)+`</h2>`);

        const component = {
            field_text: {processed: introductionProcessed},
            field_title: null,
            field_enable_read_more: true,
            __typename: 'paragraph__plain_area_text'
        };

        this.componentsData.push(component);

        return this;
    }

    /**
     * Add tags
     * @returns {SchoolComponent}
     */
    addTags()
    {
        //console.log("[SCHOOL] Tags", this.school.getTags());

        const component =
            {
                pills: this.school.getTags(),
                __typename: 'paragraph__pills'
            };

        this.componentsData.push(component);

        return this;
    }

    /**
     * Add student experience tags
     * @returns {SchoolComponent}
     */
    addStudentExperienceTags()
    {
        let raw = this.data.node.relationships.field_site_update.field_su_details_student_exp_tag;
        raw = raw.concat(...this.school.getOtherStudentExperienceTags());
        //console.log(this.school.getMemberId(),"[SCHOOL] Student Exp Tags", raw);
        // sort the raw array in ascending order
        raw.sort();
        //console.log(this.school.getMemberId(),"[SCHOOL] Student Exp Tags", raw);

        if(raw.length === 0)
        {
            return this;
        }

        let processed = [];

        raw.map((item) => {
            if (typeof item === 'string') {
                processed.push({
                    text: item.trim(),
                    enable_read_more: false,
                })
            } else if (typeof item === 'object') {
                const values = Object.values(item);

                values.forEach((text) => {
                    processed.push({
                        text: text.trim(),
                        enable_read_more: false,
                    });
                });
            }
        });

        const component = {
            heading: 'Student experience',
            bullets: processed,
            __typename: 'paragraph__bullets'
        };

        this.componentsData.push(component);

        return this;
    }

    /**
     * Add student experience tags
     * @returns {SchoolComponent}
     */
    addAcademicProgramsTags()
    {
        console.log(this.school.getMemberId());
        let raw = this.data.node.relationships.field_site_update.field_su_details_aca_prog_tag;
        raw = raw.concat(...this.school.getOtherAcademicProgramTags());
        //console.log(this.school.getMemberId(),"[SCHOOL] Student Academic Program Tags", raw);//, this.school.getOtherAcademicProgramTags());
        // sort the raw array in ascending order
        raw.sort();
        //console.log(this.school.getMemberId(),"[SCHOOL] Student Academic Program Tags", raw);//, this.school.getOtherAcademicProgramTags());

        if(raw.length === 0)
        {
            return this;
        }

        let processed = [];

        raw.map((item) => {
            if (typeof item === 'string') {
                processed.push({
                    text: item.trim(),
                    enable_read_more: false,
                })
            } else if (typeof item === 'object') {
                const values = Object.values(item);

                values.forEach((text) => {
                    processed.push({
                        text: text.trim(),
                        enable_read_more: false,
                    });
                });
            }
        });

        const component = {
            heading: 'Academic Programs',
            bullets: processed,
            __typename: 'paragraph__bullets'
        };

        this.componentsData.push(component);

        return this;
    }

    /**
     * Add Application Information block
     * @returns {SchoolComponent}
     */
    addApplicationInformation()
    {
        const fyInfo = this.data.node.relationships.field_site_update.field_su_tb_applying_to_fy;
        const trInfo = this.data.node.relationships.field_site_update.field_su_tb_applying_to_tr;
        //console.log("[SCHOOL] Application Info - FY", fyInfo);
        //console.log("[SCHOOL] Application Info - TR", trInfo);

        if(!fyInfo && !trInfo)
        {
            return this;
        }

        let cards = [];

        if(fyInfo)
        {
            cards.push(
                {
                    field_text: {
                        processed: '<p>'+Links.wrapURLs(fyInfo)+'</p>'
                    },
                    field_title: 'First-year'
                });
        }
        if(trInfo)
        {
            cards.push(
                {
                    field_text: {
                        processed: '<p>'+Links.wrapURLs(trInfo)+'</p>'
                    },
                    field_title: 'Transfer'
                });
        }

        //console.log("CARDS", cards);

        const component = {
            field_title: 'Application information',
            field_subtitle: 'Find out about requirements, fees, and deadlines',
            relationships: {
                field_cards: cards
            },
            __typename: 'paragraph__accordion'
        };

        this.componentsData.push(component);

        return this;
    }

    /**
     * Add Application Information block
     * @returns {SchoolComponent}
     */
    addAfterApplying()
    {
        const additionalInfoField = this.data.node.relationships.field_site_update.field_su_tb_additional_info;

        //console.log("[SCHOOL] After Applying", additionalInfoField);

        // If value is not set, dont render
        if(!additionalInfoField || !additionalInfoField.value)
        {
            return this;
        }

        const component = {
            field_text: {processed: Links.wrapURLs(additionalInfoField.value)},
            field_title: 'Additional Information',
            field_enable_read_more: true,
            field_override_font_styling: true,
            __typename: "paragraph__plain_area_text"
        };

        this.componentsData.push(component);

        return this;
    }

    /**
     * Add Application Information block
     * @returns {SchoolComponent}
     */
    addVisitUs()
    {
        const visitUs = this.data.node.relationships.field_site_update.field_su_tb_visiting_us;

        //console.log("[SCHOOL] Visit Us", visitUs);
        // If value is not set, dont render
        if(!visitUs)
        {
            return this;
        }
        const textSplit = visitUs.split(/\r\n|\r|\n/);
        let visitUsProcessed = '';
        textSplit.map((textChunk) => visitUsProcessed += `<p>`+Links.wrapURLs(textChunk)+`</p>`);


        const component = {
            field_text: {processed: visitUsProcessed},
            field_title: 'Visit us',
            field_enable_read_more: true,
            __typename: "paragraph__plain_area_text"
        };

        this.componentsData.push(component);

        return this;
    }

    /**
     * Add Application Information block
     * @returns {SchoolComponent}
     */
    addQuote()
    {

        const quoteText = this.data.node.relationships.field_site_update.field_su_tb_quote_text;
        // John Doe, Director of Admissiongs, University of Pittsburgh
        let quoteAuthor = "";

        if(this.data.node.relationships.field_site_update.field_su_tb_quote_first_name)
        {
            quoteAuthor += this.data.node.relationships.field_site_update.field_su_tb_quote_first_name;
        }
        if(this.data.node.relationships.field_site_update.field_su_tb_quote_last_name)
        {
            quoteAuthor += " " + this.data.node.relationships.field_site_update.field_su_tb_quote_last_name;
        }

        if(!quoteText)
        {
            return this;
        }

        if(this.data.node.relationships.field_site_update.field_su_tb_quote_position)
        {
            quoteAuthor += ", " + this.data.node.relationships.field_site_update.field_su_tb_quote_position;
        }
        if(this.data.node.relationships.field_site_update.field_su_tb_quote_deparment)
        {
            quoteAuthor += ", " + this.data.node.relationships.field_site_update.field_su_tb_quote_deparment;
        }

        //console.log("[SCHOOL] Quote Text", quoteText);
        //console.log("[SCHOOL] Quote Author", quoteAuthor);

        const component = {
            field_tagline: quoteAuthor,
            field_text: {processed: "<p>"+quoteText+"</p>"},
            __typename: "paragraph__quote_statement"
        };

        this.componentsData.push(component);

        return this;
    }



};

import React, { Component } from 'react';

export default class Loader extends Component {
    render () {
        return (
            <div style={{ paddingLeft: '170px', paddingTop: '160px', display: 'flex' }}>
                <span style={{ display: 'block', backgroundColor: '#bbb', borderRadius: '50%', marginRight: '20px', height: '6px', width: '6px' }}></span>
                <span style={{ display: 'block', backgroundColor: '#bbb', borderRadius: '50%', marginRight: '20px', height: '6px', width: '6px' }}></span>
                <span style={{ display: 'block', backgroundColor: '#bbb', borderRadius: '50%', marginRight: '20px', height: '6px', width: '6px' }}></span>
            </div>
        );
    }
}
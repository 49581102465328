/**
 * Returns an array with arrays of the given size.
 * @param array {Array} Array to chunk
 * @param chunkSize {Number} Chunk size
 */
export const chunkArray = (array, chunkSize) => {
    var results = [];
    
    while (array.length) {
        results.push(array.splice(0, chunkSize));
    }
    
    return results;
};
import React, {Component} from 'react';
//import { graphql } from 'gatsby';


import iconBulb from '../images/icons/icon-bulb.svg';
import {graphql} from "gatsby";

/**
 * This components tip box
 */
export default class TipBox extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props)
	{
        super(props);
        this.data = props;
    }



    /**
     * Render tip box
     * @returns {*}
     */
    render()
    {
        let classes = "tip-box"
        if(this.props.field_gradient_color === 'Teal') {
            classes+= " gradient-teal"
        } else if (this.props.field_gradient_color === 'Navy') {
            classes+= " gradient-navy"
        }

        return (
            <div className={classes}>
                <h4>{this.data.field_title ? this.data.field_title : ''}</h4>
                <img className="bulb-image" src={iconBulb} alt=""/>
                <div dangerouslySetInnerHTML={{__html: this.data.field_text ? this.data.field_text.processed : ''}}>
                </div>
            </div>
        )
    }
};

export const TipBoxQuery = graphql`
fragment ParagraphsTipBox on paragraph__tip_box {
  field_gradient_color
  field_title
  field_text {
    processed
  }
  
}
`;
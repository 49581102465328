import React, {Component} from 'react';
import ExploreMap from './custom-explore-map';
import capitalize from '../../utils/capitalize';

/**
 * Header component with search functionality + autocomplete
 */
export default class ExploreResultsMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSchool: null,
            schoolsInBounds: this.props.results
        };
    }

    UNSAFE_componentWillReceiveProps (props) {
        this.setState({ schoolsInBounds: props.results });
    }

    render() {
        return (
            <div className={"school-map-view-container"}>
                <div className="map-container">
                    {this.renderResults()}
                    <ExploreMap
                        onBoundsChange={schoolsInBounds => this.setState({ schoolsInBounds })}
                        onMarkerClick={school => this.selectSchool(school)}
                        results={this.props.results}
                        schoolsInBounds={this.state.schoolsInBounds}
                        allSchools={this.props.allSchools}
                        selectedSchool={this.state.selectedSchool}
                        loadingElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />} />
                </div>
            </div>
        );
    }

    selectSchool (school) {
        this.setState({ selectedSchool: school });
    }

    renderResults () {
        // Note: do not show results with invalid coordinates
        return (
            <div className="results-wrapper">
                {
                    this.state.schoolsInBounds.length ?
                        (<ul className="results-container">
                            { this.state.schoolsInBounds.filter((school) => school.getLat() && school.getLng() ).map((school, index) => this.renderResult(school, index)) }
                        </ul>) :
                        <span>Your search does not match any colleges</span>
                }
            </div>
        );
    }

    truncateTo45Chars (schoolName) {
        return schoolName.length > 45 ?
            `${schoolName.substring(0, 45)}...`: 
            schoolName;
    }

    getAcceptingStudentType (school) {
        const acceptedStudentTypes = [];
        if (school.isAcceptingFirstYearApplications()) {
            acceptedStudentTypes.push('first-year');
        }
        if (school.isAcceptingTransferApplications()) {
            acceptedStudentTypes.push('transfer');
        }
        return capitalize(acceptedStudentTypes.join(', '));
    }

    renderResult (school, index) {
        return (
            <li
                onClick={() => this.selectSchool(school)}
                className={`result-item ${this.state.selectedSchool === school ? 'result-item--is-selected' : ''}`} key={index}
            >
                <div className="school-details">
                    <div>
                        <span>{index + 1}</span>
                    </div>
                    <div>
                        <span>{this.truncateTo45Chars(school.getName())}</span>
                        <span>{school.getCityState()}</span>
                        <span style={{ display: 'none' }}>{this.getAcceptingStudentType(school)}</span>
                    </div>
                </div>
                <div className="image-container">
                    <img src={school.getGalleryImages()[0]} alt="school" />
                </div>
            </li>
        );
    }
}
import React, {Component} from 'react';
// import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// import Helmet from 'react-helmet';

// import {FaImage} from 'react-icons/fa';

class VirtualTour extends Component {

    constructor(props) {
        super(props);

        this.ipeds = props.ipeds;
    }

    componentDidMount() {
        if(typeof window === 'undefined')
            return;

        this.yvs = React.createRef();

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = "https://www.youvisit.com/tour/Embed/js2";
        s.ref = this.yvs;
        this.instance.appendChild(s);

        window.onYVExperienceOffline = function(domEl){
            domEl.parentNode.style.display = 'none';
        };

        this.forceUpdate();
    }

    componentWillUnmount() {

        delete window.vt_base_url;
        delete window.vt_image_assets_url;
        delete window.vt_immediateLaunch;
        delete window.vt_initialized;
        delete window.vt_options;
        delete window.vt_run_checks;
        delete window.vt_scope;
        delete window.vt_scriptValidated;
    }


    render() {

        return (
            <div className="gallery-content" ref={el => (this.instance = el)}>

                {/*IPEDS: {this.ipeds}*/}
                <a href="http://www.youvisit.com"
                   className="virtualtour_embed"
                   title="Virtual Reality, Virtual Tour"
                   data-remove-prompts="1"
                   data-remove-registration="1"
                   data-link-type="immersive"
                   data-iped={this.ipeds}
                   data-image-width="100%"
                   data-image-height="400"
                   data-platform="commonapp">
                    Virtual Tour
                </a>
            </div>
        );
    }
}

export default VirtualTour;